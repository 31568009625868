import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  CardImg,
  CardTitle,
  CardText,
  Table,
} from "reactstrap";
import * as Yup from "yup";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Formik, Form, Field } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { addClasses, editClasses } from "../../../store/classes/actions";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import NoImgFound from "../../../assets/No-image-found.jpg";
import { startLoader } from "../../../store/actions";
import { DatePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import * as moment from "moment-timezone";

const ClassesClone = (props) => {
  //meta title
  document.title = "Edit Class";
  moment.tz.setDefault("Etc/UTC");

  const { singleClass } = useSelector((state) => state.Classes);

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [data, setData] = useState({
    title: "",
    startTime: "",
    firstSessionDate: null,
    endByDate: null,
    limitNumberOfSessions: null,
    location: {},
    description: "",
    price: "",
    minCancelWindow: null,
    isPublic: true,
    isOutdoor: true,
    duration: 0,
    totalSeat: 1,
    instructor: {},
    days: [],
    classImage: "",
  });

  const CloneClass = Yup.object().shape({
    startTime: Yup.date().required("This field is required"),
  });

  const handleSubmit = (e) => {
    dispatch(startLoader());
    dispatch(addClasses(data, history));
  };

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));

    setTokenHeader(data.access_token);
    dispatch(editClasses(params.id));
    console.log(singleClass);
  }, []);
  const processTime = (date) => {
    console.log(date);
    let Date = date.$d;
    Date = Date.toUTCString();
    return Date;
  };

  useEffect(() => {
    if (singleClass) {
      setData({
        title: singleClass?.title,
        description: singleClass?.description,
        startTime: "",
        firstSessionDate: null,
        endByDate: null,
        limitNumberOfSessions: null,
        duration: singleClass?.duration,
        price: singleClass?.price,
        minCancelWindow: singleClass?.minCancelWindow,
        isPublic: singleClass?.isPublic,
        isOutdoor: singleClass?.isOutdoor,
        location: singleClass?.location,
        instructor: singleClass?.instructor,
        totalSeat: singleClass?.totalSeat,
        classImage: singleClass?.classImage,
        days: singleClass?.days,
      });
    }
    console.log("Class Data : ", data);
  }, [singleClass]);

  function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Class" breadcrumbItem="Clone the Class" />
          <Card ClassName="px-3">
            <Row>
              <Col className="col-lg-4 pt-2">
                {singleClass.classImage ? (
                  <CardImg
                    top
                    className="img-fluid thumbnail"
                    src={`${process.env.REACT_APP_ASSET_URL}${singleClass.classImage}`}
                    alt="Skote"
                  />
                ) : (
                  <CardImg
                    top
                    className="img-fluid"
                    src={NoImgFound}
                    alt="Skote"
                  />
                )}
              </Col>
              <Col lg={6}>
                <CardBody>
                  <CardTitle className="mt-0">{singleClass?.title}</CardTitle>
                  <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th scope="row">Instructor :</th>
                        <td>{singleClass?.instructor?.name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Location :</th>
                        <td>{singleClass?.location?.name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Duration :</th>
                        <td>{singleClass?.duration} minutes</td>
                      </tr>
                      <tr>
                        <th scope="row">Total Seats :</th>
                        <td>{singleClass?.totalSeat}</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Col>
              <Col className="col-md-12 p-3">
                <Label>Description</Label>
                <CardText>{singleClass?.description}</CardText>
              </Col>
            </Row>
          </Card>

          <Formik
            initialValues={{ ...data }}
            validationSchema={CloneClass}
            enableReinitialize
            onSubmit={(e) => {
              handleSubmit();
              return false;
            }}
          >
            {({ values, setValues, setFieldValue, errors, touched }) => (
              <Row>
                <Col xl={8}>
                  <Card>
                    <CardBody>
                      <Form>
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-6 d-flex flex-column">
                              <Label htmlFor="formrow-firstname-Input">
                                Start Time
                              </Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  ClassName="form-control"
                                  name="startTime"
                                  onChange={(newValue) => {
                                    var time = processTime(newValue);
                                    setData({ ...data, startTime: time });
                                    console.log("updated Data : ", data);
                                  }}
                                />
                              </LocalizationProvider>
                              {errors.startTime && touched.startTime ? (
                                <div className="text-danger input-error">
                                  {errors.startTime}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-4 d-flex flex-column">
                              <Label htmlFor="formrow-firstname-Input">
                                First Session Date <small>( Optional )</small>
                              </Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  type="date"
                                  className="form-control"
                                  id="firstSessionDate"
                                  name="firstSessionDate"
                                  onChange={(newValue) => {
                                    setData({
                                      ...data,
                                      firstSessionDate: newValue?.$d,
                                    });
                                    setFieldValue(
                                      "firstSessionDate",
                                      newValue?.$d
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                              <Label htmlFor="formrow-firstname-Input">
                                End By Date <small>( Optional )</small>
                              </Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  type="date"
                                  className="form-control"
                                  id="endByDate"
                                  name="endByDate"
                                  onChange={(newValue) => {
                                    setData({
                                      ...data,
                                      endByDate: newValue?.$d,
                                    });
                                    setFieldValue("endByDate", newValue?.$d);
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                              <Label htmlFor="formrow-firstname-Input">
                                Limit Number of Sessions{" "}
                                <small>( Optional )</small>
                              </Label>
                              <Field
                                type="number"
                                value={null}
                                className="form-control"
                                id="limitNumberOfSessions"
                                name="limitNumberOfSessions"
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    limitNumberOfSessions: e.target.value,
                                  });
                                  setFieldValue(
                                    "limitNumberOfSessions",
                                    e.target.value
                                  );
                                }}
                              />
                              {errors.duration && touched.duration ? (
                                <div className="text-danger input-error">
                                  {errors.duration}
                                </div>
                              ) : null}
                            </div>
                            &nbsp;
                            <div className="mb-3">
                              <Label htmlFor="formrow-firstname-Input">
                                Min Cancellation Window (hours){" "}
                                <small>(optional)</small>
                              </Label>
                              <Field
                                type="number"
                                value={data.minCancelWindow}
                                className="form-control"
                                id="minCancelWindow"
                                name="totalminCancelWindowSeat"
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    minCancelWindow: e.target.value,
                                  });
                                  setFieldValue(
                                    "minCancelWindow",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="pt-5">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Formik>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  );
};

export default ClassesClone;
