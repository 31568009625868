import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from "react-toastify";

// Crypto Redux States
import {
  GET_PROVIDERS,
  ADD_PROVIDERS,
  DELETE_PROVIDERS,
  EDIT_PROVIDERS,
  UPDATE_PROVIDERS,
  GET_NOT_VERIFIED_PROVIDERS,
  GET_PROVIDERS_DETAIL,
  SETTLE_PROVIDER_PAYMENT,
} from "./actionTypes";
import {
  getProvidersFail,
  getProvidersSuccess,
  addProvidersFail,
  addProvidersSuccess,
  deleteProvidersSuccess,
  deleteProvidersFail,
  editProvidersSuccess,
  editProvidersFail,
  updateProvidersFail,
  updateProvidersSuccess,
  getNotVerifiedProvidersSuccess,
  getNotVerifiedProvidersFail,
  getProvidersDetailSuccess,
  getProvidersDetailFail,
  getProviders,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getProvidersApi,
  addNewProvidersApi,
  deleteProvidersApi,
  editProvidersApi,
  updateProvidersApi,
  getNotVerifiedProvidersApi,
  getProvidersDetailApi,
  settleProviderPaymentApi,
} from "../../helpers/backend_helper";
import { stopLoader } from "../loaderState/actions";

function* getProvidersData({ payload: { page, searchKeyword } }) {
  try {
    let response = yield call(getProvidersApi, page, searchKeyword);
    yield put(getProvidersSuccess(response));
  } catch (error) {
    yield put(getProvidersFail(error));
  }
  yield put(stopLoader());
}

function* getNotVerifiedProvidersData({ payload: page }) {
  try {
    let response = yield call(getNotVerifiedProvidersApi, page.page);
    yield put(getNotVerifiedProvidersSuccess(response));
  } catch (error) {
    yield put(getNotVerifiedProvidersFail(error));
  }
  yield put(stopLoader());
}

function* addProvidersData({ payload: { data, history } }) {
  try {
    console.log(data);

    let response = yield call(addNewProvidersApi, data);
    console.log(response);
    if (response.status == 201) {
      yield put(getProvidersSuccess(response));
      toast.success("Instructor Added Successfully!!");
      history.push("/all-providers");
    } else {
      toast.error(response.message);
      yield put(getProvidersFail(response.data.message));
    }
  } catch (error) {
    yield put(getProvidersFail(error));
  }
  yield put(stopLoader());
}

function* settleProviderPayments({ payload: { data, callback } }) {
  try {
    let response = yield call(settleProviderPaymentApi, data);
    console.log(response);
    if (response.data.status == 201) {
      toast.success("Payment sent");

      yield put(editProviders(response.data.data.instructorId));
    } else {
      toast.error(response.message);
      yield put(getProvidersFail(response.data.message));
    }
  } catch (error) {
    yield put(getProvidersFail(error));
  } finally {
    yield put(stopLoader());
    callback();
  }
}

function* editProviders({ payload: id }) {
  try {
    let response = yield call(editProvidersApi, id.id);
    yield put(editProvidersSuccess(response));
  } catch (error) {
    yield put(editProvidersFail(error));
  }
  yield put(stopLoader());
}

function* getProvidersDetail({ payload: id }) {
  try {
    let response = yield call(getProvidersDetailApi, id.id);
    yield put(getProvidersDetailSuccess(response));
  } catch (error) {
    yield put(getProvidersDetailFail(error));
  }
  yield put(stopLoader());
}

function* deleteProviders({ payload: id }) {
  try {
    const response = yield call(deleteProvidersApi, id.id);
    console.log("response = > ", response);
    if (response.status == 200) {
      yield put(deleteProvidersSuccess(id));
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    yield put(deleteProvidersFail(error));
  }
  yield put(stopLoader());
}

function* onUpdateProviders({ payload: { id, user, history } }) {
  try {
    console.log("id = > ", id);
    const response = yield call(updateProvidersApi, id, user);
    console.log(response.data);

    if (response.data.status == "200") {
      // yield put(updateProvidersSuccess(response.data))
      toast.success("Instructor Updated Successfully!!");

      history.push("/all-providers");
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    yield put(updateProvidersFail(error));
  }
  yield put(stopLoader());
}

function* providersSaga() {
  yield takeEvery(GET_PROVIDERS, getProvidersData);
  yield takeEvery(GET_PROVIDERS_DETAIL, getProvidersDetail);
  yield takeEvery(GET_NOT_VERIFIED_PROVIDERS, getNotVerifiedProvidersData);
  yield takeEvery(ADD_PROVIDERS, addProvidersData);
  yield takeEvery(EDIT_PROVIDERS, editProviders);
  yield takeEvery(DELETE_PROVIDERS, deleteProviders);
  yield takeEvery(UPDATE_PROVIDERS, onUpdateProviders);
  yield takeEvery(SETTLE_PROVIDER_PAYMENT, settleProviderPayments);
}

export default providersSaga;
