import React, { useEffect, useState } from "react";
import { cilPencil, cilTrash, cilUser } from "@coreui/icons";
import Image from "react-bootstrap/Image";

import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import DataTable from "react-data-table-component";
import { Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CIcon from "@coreui/icons-react";
import { useHistory, Link } from "react-router-dom";
import { toastConfirm } from "../../../common/toast";
import { useDispatch, useSelector } from "react-redux";
import { setTokenHeader } from "../../../helpers/api_helper";
import NoImgFound from "../../../assets/No-image-found.jpg";
import { startLoader } from "../../../store/loaderState/actions";
import {
  deleteProviders,
  getProviders,
} from "../../../store/providers/actions";
import moment from "moment";

const Providers = () => {
  document.title = "Admin | All Instructors";

  const [searchKeyword, setSearchKeyword] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const dispatch = useDispatch();
  const history = useHistory();
  const { providers } = useSelector((state) => state.Providers);
  const deleteProvidersWithId = async (id) => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire();
    if (response.isConfirmed) {
      dispatch(deleteProviders(id, history));
    }
  };

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));
    setTokenHeader(data.access_token);
    dispatch(startLoader());
    dispatch(getProviders());
  }, []);

  useEffect(() => {
    console.log("providers", providers);
  }, [providers]);

  useEffect(() => {
    dispatch(startLoader());
    dispatch(getProviders(page, searchKeyword));
  }, [page]);

  useEffect(() => {
    dispatch(getProviders(page, searchKeyword));
  }, [searchKeyword]);
  const columns = [
    {
      name: "Sr. no",
      selector: (row, index) => {
        if (providers?.pagination?.currentPage - 1 != 0) {
          return (providers?.pagination?.currentPage - 1) * 10 + (index + 1);
        }
        return index + 1;
      },
      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => row.fname,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lname,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Registered On",
      selector: (row) => moment(row.createdAt).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <Link to={`providers/${row._id}/edit`}>
            <CIcon
              icon={cilPencil}
              className="text-warning hand me-2"
              onClick={() => {
                // toggleViewModal()
                // setEdit(row);
              }}
            />
          </Link>
          <Link to={`providers/${row._id}/view`} className="actionIcons">
            <CIcon
              icon={cilUser}
              className="text-success hand "
              style={{ height: "20px !important" }}
              title="View Detail"
              onClick={() => {
                // toggleViewModal()
                // setEdit(row);
              }}
            />
          </Link>

          <CIcon
            icon={cilTrash}
            className="text-danger hand"
            onClick={() => {
              deleteProvidersWithId(row._id);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content cast ">
        <div className="container-fluid">
          <Breadcrumbs
            title="All Instructors"
            breadcrumbItem="Manage Instructors"
          />

          <Row>
            <Col xl={12}>
              <div className="mb-2">
                <Link
                  to={"/all-providers/add"}
                  className="btn btn-primary w-md"
                >
                  Add +
                </Link>
              </div>
              <CCardGroup className="mb-2">
                <CCard>
                  <CCardBody>
                    <div className="row">
                      <div className="col-md-8"></div>
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control"
                          id="search"
                          name="search"
                          placeHolder="Search Instructor"
                          onChange={(e) => {
                            setSearchKeyword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>

              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={providers?.list}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={providers?.pagination?.total}
                      paginationPerPage={countPerPage}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      onChangePage={(page, totalRows) => {
                        console.log("page : ", page);
                        console.log("totalRows : ", totalRows);
                        setPage(page);
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Providers;
