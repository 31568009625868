/** Get Dashboard Chart data */
export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const GET_HOLIDAYS_SUCCESS = "GET_HOLIDAYS_SUCCESS";
export const GET_HOLIDAYS_FAIL = "GET_HOLIDAYS_FAIL";
export const SAVE_HOLIDAY = "SAVE_HOLIDAY";
export const SAVE_HOLIDAY_SUCCESS = "SAVE_HOLIDAY_SUCCESS";
export const SAVE_HOLIDAY_FAIL = "SAVE_HOLIDAY_FAIL";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const DELETE_HOLIDAY_SUCCESS = "DELETE_HOLIDAY_SUCCESS";
export const DELETE_HOLIDAY_FAIL = "DELETE_HOLIDAY_FAIL";
