import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  GET_CLASSES,
  ADD_CLASSES,
  DELETE_CLASSES,
  EDIT_CLASSES,
  UPDATE_CLASSES,
  GET_CLASS_SESSIONS,
  GET_SESSION_DETAILS,
  ADD_SESSION,
  EDIT_SESSION,
  CANCEL_SESSION,
} from "./actionTypes";
import {
  getClassesFail,
  getClassesSuccess,
  addClassesFail,
  addClassesSuccess,
  deleteClassesSuccess,
  deleteClassesFail,
  editClassesSuccess,
  editClassesFail,
  cancelSessionSuccess,
  cancelSessionFail,
  getClassSessionsSuccess,
  getClassSessionsFail,
  getSessionDetailsSuccess,
  getSessionDetailsFail,
  addSessionSuccess,
  addSessionFail,
  editSessionSuccess,
  editSessionFail,
} from "./actions";
import {
  getClassesApi,
  addNewClassesApi,
  deleteClassesApi,
  editClassesApi,
  updateClassesApi,
  getClassSessionsApi,
  getSessionDetailsApi,
  addSessionApi,
  editSessionApi,
  cancelSessionApi,
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { stopLoader } from "../loaderState/actions";
import moment from "moment";

function* getClassesData({ payload: { filterData } }) {
  console.log("getClassesData", filterData);
  if (!filterData) filterData = {};

  try {
    const keys = Object.keys(filterData);
    keys.map((key) => {
      if (filterData[key] && !filterData[key].length && filterData[key] == "") {
        filterData[key] = null;
      }
    });
    let response = yield call(getClassesApi, filterData);
    yield put(getClassesSuccess(response));
  } catch (error) {
    console.log("error : ", error);
    yield put(getClassesFail(error));
  }
  yield put(stopLoader());
}

function* getClassSessions({ payload: { id } }) {
  try {
    let response = yield call(getClassSessionsApi, id);
    yield put(getClassSessionsSuccess(response));
  } catch (error) {
    yield put(getClassSessionsFail(error));
  }
  yield put(stopLoader());
}

function* getSessionDetails({ payload: { id } }) {
  try {
    let response = yield call(getSessionDetailsApi, id);
    yield put(getSessionDetailsSuccess(response.data));
  } catch (error) {
    yield put(getSessionDetailsFail(error));
  }
  yield put(stopLoader());
}

function* addSession({ payload: { data, history, classId } }) {
  try {
    console.log(data);

    let classObj = {
      classId: classId,
      date: data.date.toISOString(),
      instructor: data.instructor?._id,
    };

    console.log("addSession", classObj);

    let response = yield call(addSessionApi, classObj);

    // TODO find out why this is necessary
    if (response.status != 200) {
      toast.error(response.message);
    } else {
      yield put(addSessionSuccess(response.data));
      toast.success("Session Added Successfully!!");
      history.push(`/classes/${classId}/sessions`);
    }
  } catch (error) {
    yield put(addSessionFail(error));
  }
  yield put(stopLoader());
}

function* editSession({ payload: { data, history, classId, sessionId } }) {
  try {
    console.log(data);

    let classObj = {
      classId: classId,
      sessionId: sessionId,
      instructor: data.instructor?._id,
    };

    console.log("editSession", classObj);

    let response = yield call(editSessionApi, classObj);

    // TODO find out why this is necessary
    if (response.status != 200) {
      toast.error(response.message);
    } else {
      yield put(editSessionSuccess(response.data));
      toast.success("Session Updated Successfully!!");
      history.push(`/classes/${classId}/sessions`);
    }
  } catch (error) {
    yield put(editSessionFail(error));
  }
  yield put(stopLoader());
}

function* cancelSession({ payload: { sessionId, cancelNote } }) {
  try {
    console.log("cancelSession", sessionId);

    let response = yield call(cancelSessionApi, sessionId, {
      cancelNote: cancelNote,
    });

    // TODO find out why this is necessary
    if (response.status != 200) {
      toast.error(response.message);
    } else {
      yield put(cancelSessionSuccess(response.data));
      toast.success("Session Cancelled!");
    }
  } catch (error) {
    yield put(cancelSessionFail(error));
  }
  yield put(stopLoader());
}

const changeTimeFormat = (data, times) => {
  // const date = moment(data).format("DD/MM/YYYY");
  // const converted = moment(times).format("hh:mm a");

  // return moment(date + ' ' + converted, 'DD/MM/YYYY hh:mm a');

  const time = moment(times);
  const date2 = moment(data);
  const converted = time.format("hh:mm a");
  const parsedTime = moment(converted, "hh:mm a");
  console.log();
  const startTime = date2
    .set({
      hour: parsedTime.hour(),
      minute: parsedTime.minute(),
      second: 0,
      millisecond: 0,
    })
    .toISOString();
  return startTime;
};

function* addClassesData({ payload: { data, history } }) {
  var firstSessionDate = null;
  if (data.firstSessionDate) {
    let date = moment(data.firstSessionDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    firstSessionDate = date.toISOString();
  }

  var endByDate = null;
  if (data.endByDate) {
    let date = moment(data.endByDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    endByDate = date.toISOString();
  }

  try {
    console.log(data);

    let classObj = {
      title: data.title,
      description: data.description,
      startTime: changeTimeFormat(data.date, data.startTime),
      firstSessionDate: firstSessionDate,
      endByDate: endByDate,
      limitNumberOfSessions: data.limitNumberOfSessions,
      duration: `${data.duration}`,
      price: data.price,
      minCancelWindow: data.minCancelWindow,
      totalSeat: data.totalSeat,
      locationId: data.location._id,
      instructorId: data.instructor._id,
      // file: data.file ? data.file : "",
      isPublic: data.isPublic,
      isOutdoor: data.isOutdoor,
      days: data.days,
      classImage: data.classImage,
    };

    console.log("addClassesData", classObj);

    // let response = yield call(addNewClassesApi, classObj);
    // // console.log(response);

    // if (response.status == 201) {
    //   yield put(getClassesSuccess(response));
    //   toast.success("Class Added Successfully!!");
    //   history.push("/all-classes");
    // } else {
    //   toast.error(response.message);
    // }
  } catch (error) {
    console.log(error);
    toast.error(error);
    yield put(getClassesFail(error));
  }
  yield put(stopLoader());
}

function* editClasses({ payload: id }) {
  try {
    let response = yield call(editClassesApi, id.id);
    yield put(editClassesSuccess(response));
  } catch (error) {
    yield put(editClassesFail(error));
  }
  yield put(stopLoader());
}

function* onUpdateClasses({ payload: { id, data, history } }) {
  var firstSessionDate = null;
  if (data.firstSessionDate) {
    let date = moment(data.firstSessionDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    firstSessionDate = date.toISOString();
  }

  var endByDate = null;
  if (data.endByDate) {
    let date = moment(data.endByDate).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    endByDate = date.toISOString();
  }

  try {
    let classObj = {
      title: data.title,
      description: data.description,
      startTime: changeTimeFormat(data.date, data.startTime),
      firstSessionDate: firstSessionDate,
      endByDate: endByDate,
      limitNumberOfSessions: data.limitNumberOfSessions,
      duration: `${data.duration}`,
      price: data.price,
      minCancelWindow: data.minCancelWindow,
      totalSeat: data.totalSeat,
      locationId: data.location._id,
      instructorId: data.instructor._id,
      isPublic: data.isPublic,
      isOutdoor: data.isOutdoor,
      days: data.days,
    };

    if (data.classImage != undefined && data.classImage != "") {
      classObj.classImage = data.classImage;
    }

    // if (data.file != undefined && data.file != "") {
    //   classObj.file = data.file;
    // }

    console.log("onUpdateClasses", classObj);

    const response = yield call(updateClassesApi, id, classObj);
    console.log(response);

    if (response?.data?.status == 200) {
      toast.success("Class updated Successfully!!");
      history.push("/all-classes");
    } else {
      toast.error(response?.data?.message);
    }
  } catch (error) {
    console.log(error?.response?.data?.message);
    toast.error(error?.response?.data?.message);
  }
  yield put(stopLoader());
}

function* deleteClasses({ payload: id, history }) {
  try {
    const response = yield call(deleteClassesApi, id.id);
    if (response?.status == 200) {
      toast.success("Class Deleted Successfully!!");
      yield put(deleteClassesSuccess(id.id));
    } else {
      toast.error(response.message);
    }
    console.log(response);
  } catch (error) {
    yield put(deleteClassesFail(error));
  }
}

function* classesSaga() {
  yield takeEvery(GET_CLASSES, getClassesData);
  yield takeEvery(ADD_CLASSES, addClassesData);
  yield takeEvery(GET_CLASS_SESSIONS, getClassSessions);
  yield takeEvery(GET_SESSION_DETAILS, getSessionDetails);
  yield takeEvery(ADD_SESSION, addSession);
  yield takeEvery(EDIT_SESSION, editSession);
  yield takeEvery(CANCEL_SESSION, cancelSession);
  yield takeEvery(EDIT_CLASSES, editClasses);
  yield takeEvery(DELETE_CLASSES, deleteClasses);
  yield takeEvery(UPDATE_CLASSES, onUpdateClasses);
}

export default classesSaga;
