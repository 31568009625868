import {
  GET_CLASSES,
  GET_CLASSES_SUCCESS,
  GET_CLASSES_FAIL,
  GET_CLASS_SESSIONS,
  GET_CLASS_SESSIONS_SUCCESS,
  GET_CLASS_SESSIONS_FAIL,
  GET_SESSION_DETAILS,
  GET_SESSION_DETAILS_SUCCESS,
  GET_SESSION_DETAILS_FAIL,
  ADD_SESSION,
  ADD_SESSION_SUCCESS,
  ADD_SESSION_FAIL,
  ADD_CLASSES,
  ADD_CLASSES_SUCCESS,
  ADD_CLASSES_FAIL,
  EDIT_SESSION,
  EDIT_SESSION_SUCCESS,
  EDIT_SESSION_FAIL,
  CANCEL_SESSION,
  CANCEL_SESSION_SUCCESS,
  CANCEL_SESSION_FAIL,
  DELETE_CLASSES,
  DELETE_CLASSES_SUCCESS,
  DELETE_CLASSES_FAIL,
  EDIT_CLASSES_SUCCESS,
  EDIT_CLASSES,
  EDIT_CLASSES_FAIL,
  UPDATE_CLASSES_SUCCESS,
  UPDATE_CLASSES,
  UPDATE_CLASSES_FAIL,
} from "./actionTypes";

export const getClasses = (filterData) => ({
  type: GET_CLASSES,
  payload: { filterData },
});

export const getClassesSuccess = (data) => ({
  type: GET_CLASSES_SUCCESS,
  payload: { data },
});

export const getClassesFail = (error) => ({
  type: GET_CLASSES_FAIL,
  payload: { error },
});

export const getClassSessions = (id) => ({
  type: GET_CLASS_SESSIONS,
  payload: { id },
});

export const getClassSessionsSuccess = (data) => ({
  type: GET_CLASS_SESSIONS_SUCCESS,
  payload: { data },
});

export const getClassSessionsFail = (error) => ({
  type: GET_CLASS_SESSIONS_FAIL,
  payload: { error },
});

export const getSessionDetails = (id) => ({
  type: GET_SESSION_DETAILS,
  payload: { id },
});

export const getSessionDetailsSuccess = (data) => ({
  type: GET_SESSION_DETAILS_SUCCESS,
  payload: { data },
});

export const getSessionDetailsFail = (error) => ({
  type: GET_SESSION_DETAILS_FAIL,
  payload: { error },
});

export const addSession = (data, history, classId) => ({
  type: ADD_SESSION,
  payload: { data, history, classId },
});

export const addSessionSuccess = (data) => ({
  type: ADD_SESSION_SUCCESS,
  payload: { data },
});

export const addSessionFail = (error) => ({
  type: ADD_SESSION_FAIL,
  payload: { error },
});

export const editSession = (data, history, classId, sessionId) => ({
  type: EDIT_SESSION,
  payload: { data, history, classId, sessionId },
});

export const editSessionSuccess = (data) => ({
  type: EDIT_SESSION_SUCCESS,
  payload: { data },
});

export const editSessionFail = (error) => ({
  type: EDIT_SESSION_FAIL,
  payload: { error },
});

export const cancelSession = (sessionId, cancelNote) => ({
  type: CANCEL_SESSION,
  payload: { sessionId, cancelNote },
});

export const cancelSessionSuccess = (data) => ({
  type: CANCEL_SESSION_SUCCESS,
  payload: { data },
});

export const cancelSessionFail = (error) => ({
  type: CANCEL_SESSION_FAIL,
  payload: { error },
});

export const addClasses = (data, history) => ({
  type: ADD_CLASSES,
  payload: { data, history },
});

export const addClassesSuccess = (data) => ({
  type: ADD_CLASSES_SUCCESS,
  payload: { data },
});

export const addClassesFail = (error) => ({
  type: ADD_CLASSES_FAIL,
  payload: { error },
});

export const deleteClasses = (id, history) => ({
  type: DELETE_CLASSES,
  payload: { id, history },
});

export const deleteClassesSuccess = (data) => ({
  type: DELETE_CLASSES_SUCCESS,
  payload: { data },
});

export const deleteClassesFail = (error) => ({
  type: DELETE_CLASSES_FAIL,
  payload: { error },
});
export const editClasses = (id) => ({
  type: EDIT_CLASSES,
  payload: { id },
});

export const editClassesSuccess = (data) => ({
  type: EDIT_CLASSES_SUCCESS,
  payload: { data },
});

export const editClassesFail = (error) => ({
  type: EDIT_CLASSES_FAIL,
  payload: { error },
});

export const updateClassesSuccess = (classes) => ({
  type: UPDATE_CLASSES_SUCCESS,
  payload: classes,
});

export const updateClasses = (id, data, history) => ({
  type: UPDATE_CLASSES,
  payload: { id, data, history },
});
export const updateClassesFail = (error) => ({
  type: UPDATE_CLASSES_FAIL,
  payload: error,
});
