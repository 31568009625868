import React, { useEffect, useState } from "react";
import { cilPencil, cilTrash, cilUser } from "@coreui/icons";
import Image from "react-bootstrap/Image";

import DataTable from "react-data-table-component";
import { Row, Col, Label } from "reactstrap";
import { Field, Formik, Form } from "formik";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CCard,
  CCardBody,
  CCardGroup,
} from "@coreui/react";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CIcon from "@coreui/icons-react";
import dayjs from "dayjs";
import { toastConfirm } from "../../../common/toast";
import {
  getHolidays,
  saveHoliday,
  deleteHoliday,
} from "../../../store/actions";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTokenHeader } from "../../../helpers/api_helper";
import { startLoader } from "../../../store/loaderState/actions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers";
import UTCDatePicker from "../../../common/utcDatePicker";
import * as moment from "moment-timezone";

const Holidays = () => {
  document.title = "Admin | Holidays";
  moment.tz.setDefault("Etc/UTC");

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const [searchKeyword, setSearchKeyword] = useState("");
  const dispatch = useDispatch();
  const { holidays } = useSelector((state) => state.Holidays);
  const [holidayDialogIsVisible, setHolidayDialogIsVisible] = useState(false);
  const [holidayData, setHolidayData] = useState({});

  const deleteHolidayWithId = async (id) => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this holiday?")
    ).fire();
    if (response.isConfirmed) {
      dispatch(deleteHoliday(id));
      await delay(1000); // TODO find a better way
      refreshHolidays();
    }
  };

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));
    setTokenHeader(data.access_token);
    dispatch(startLoader());
    refreshHolidays();
  }, []);

  const refreshHolidays = async () => {
    let startDate = moment().startOf("month");
    let endDate = startDate.add(1, "year");
    dispatch(
      getHolidays(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"))
    );
  };

  const submitHolidayData = async () => {
    console.log("newHolidayData:", holidayData);
    dispatch(startLoader());
    dispatch(saveHoliday(holidayData));
    await delay(1000);
    refreshHolidays();
  };

  const columns = [
    {
      name: "Enabled",
      width: "100px",
      center: true,
      selector: (row) => (row.enabled ? "\u2713" : ""),
    },
    {
      name: "Name",
      width: "400px",
      selector: (row) => row.name,
      //   sortable: true,
    },
    {
      name: "Date",
      width: "200px",
      selector: (row) => moment(row.date).format("MM/DD/YYYY"),
      //   sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <CIcon
            icon={cilPencil}
            className="text-warning hand me-2"
            onClick={() => {
              setHolidayData(row);
              setHolidayDialogIsVisible(true);
            }}
          />

          <CIcon
            icon={cilTrash}
            className="text-danger hand"
            onClick={() => {
              deleteHolidayWithId(row._id);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content cast ">
        <div className="container-fluid">
          <Breadcrumbs title="All Holidays" breadcrumbItem="Manage Holidays" />

          <Row>
            <Col xl={12}>
              <div className="mb-2">
                <Link
                  to="/holidays"
                  className="btn btn-primary w-md"
                  onClick={() => {
                    setHolidayData({ enabled: true });
                    setHolidayDialogIsVisible(true);
                  }}
                >
                  Add +
                </Link>
              </div>

              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={holidays}
                      highlightOnHover
                      pagination
                      paginationServer
                      keyField={"keyField"}
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </Col>
          </Row>
          <CModal
            backdrop="static"
            visible={holidayDialogIsVisible}
            onClose={() => setHolidayDialogIsVisible(false)}
            aria-labelledby="AddHolidy"
          >
            <CModalHeader onClose={() => setHolidayDialogIsVisible(false)}>
              <CModalTitle id="AddHolidy">
                {holidayData?._id ? "Edit Holiday:" : "Create New Holiday:"}
              </CModalTitle>
            </CModalHeader>
            <Formik
              enableReinitialize
              initialValues={{ ...holidayData }}
              onSubmit={(e) => {
                return false;
              }}
            >
              {({ values, setValues, setFieldValue, errors, touched }) => (
                <Form>
                  {" "}
                  <CModalBody>
                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Enabled: </Label>{" "}
                      &nbsp; &nbsp;
                      <Field
                        id="enabled"
                        type="checkbox"
                        name="enabled"
                        onChange={(e) => {
                          setHolidayData({
                            ...holidayData,
                            enabled: e.target.checked,
                          });
                          setFieldValue("enabled", e.target.checked);
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="formrow-firstname-Input">Name :</Label>
                      <Field
                        type="text"
                        className="form-control"
                        id="holidayName"
                        name="holidayName"
                        value={holidayData?.name}
                        onChange={(e) => {
                          setHolidayData({
                            ...holidayData,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-12 d-flex flex-column">
                      <Label htmlFor="formrow-firstname-Input">Date :</Label>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <UTCDatePicker
                            type="date"
                            value={
                              holidayData?.date
                                ? dayjs(holidayData?.date)
                                : null
                            }
                            className="form-control"
                            id="firstSessionDate"
                            name="firstSessionDate"
                            onChange={(newValue) => {
                              console.log("newValue", newValue);
                              setHolidayData({
                                ...holidayData,
                                date: newValue,
                              });
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </CModalBody>
                </Form>
              )}
            </Formik>

            <CModalFooter>
              <CButton
                color="secondary"
                onClick={() => setHolidayDialogIsVisible(false)}
              >
                Cancel
              </CButton>
              <CButton
                color="primary"
                onClick={() => {
                  setHolidayDialogIsVisible(false);
                  submitHolidayData();
                }}
              >
                Save
              </CButton>
            </CModalFooter>
          </CModal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Holidays;
