export const ADMIN_LOGIN_URL = "/login";
export const GET_DASHBOARD_DATA = "/dashboard";

// Classes Api
export const GET_CLASSES = "/getClass";
export const ADD_NEW_CLASSES = "/class";
export const GET_CLASS_SESSIONS = "/classSessions";
export const GET_SESSION_DETAILS = "/session";
export const ADD_SESSION = "/session";
export const EDIT_SESSION = "/editSession";
export const CANCEL_SESSION = "/session";
export const EDIT_CLASSES = "/class";
export const UPDATE_CLASSES = "/class";
export const UPDATE_CLASSES_IMAGE = "/admin/uploadImage";
export const DELETE_CLASSES = "/class";
export const POST_LOGIN = "/login";

// Users Api
export const GET_USERS = "/user";
export const ADD_NEW_USERS = "/user";
export const EDIT_USERS = "/user";
export const UPDATE_USERS = "/user";
export const UPDATE_USERS_IMAGE = "/admin/uploadImage";
export const DELETE_USERS = "/user";

// Categories Api
export const GET_CATEGORIES = "/getCategory";
export const ADD_NEW_CATEGORIES = "/category";
export const EDIT_CATEGORIES = "/getCategory";
export const UPDATE_CATEGORIES = "/updateCategory";
export const UPDATE_CATEGORIES_IMAGE = "/admin/uploadImage";
export const DELETE_CATEGORIES = "/deleteCategory";

// Incidents Api
export const GET_INCIDENTS = "/getIncident";
export const GET_REPORTEDINCIDENTS = "/getReportedIncident";
export const ADD_NEW_INCIDENTS = "/incident";
export const EDIT_INCIDENTS = "/getIncident";
export const UPDATE_INCIDENTS = "/updateIncident";
export const UPDATE_INCIDENTS_IMAGE = "/admin/uploadImage";
export const DELETE_INCIDENTS = "/deleteIncident";

// CMS Api
export const GET_CMS = "/getCms";
export const ADD_NEW_CMS = "/addCms";
export const EDIT_CMS = "/getCms";
export const UPDATE_CMS = "/updateCms";
export const UPDATE_CMS_IMAGE = "/admin/uploadImage";
export const DELETE_CMS = "/deleteCms";

// Transactions Api
export const GET_TRANSACTIONS = "/instructorTransaction";
export const GET_USER_TRANSACTIONS = "/userTransaction";
export const ADD_NEW_TRANSACTIONS = "/addCms";
export const EDIT_TRANSACTIONS = "/getCms";
export const UPDATE_TRANSACTIONS = "/updateCms";
export const UPDATE_TRANSACTIONS_IMAGE = "/admin/uploadImage";
export const DELETE_TRANSACTIONS = "/deleteCms";

// Splash screen Api
export const GET_SPLASH_SCREEN = "/getScreen";
export const ADD_NEW_SPLASH_SCREEN = "/addScreen";
export const EDIT_SPLASH_SCREEN = "/getCategory";
export const UPDATE_SPLASH_SCREEN = "/updateScreen";
export const UPDATE_SPLASH_SCREEN_IMAGE = "/admin/uploadImage";
export const DELETE_SPLASH_SCREEN = "/deleteScreen";

// Instructors Api
export const GET_PROVIDERS = "/provider";
export const ADD_NEW_PROVIDERS = "/provider";
export const EDIT_PROVIDERS = "/provider";
export const UPDATE_PROVIDERS = "/provider";
export const UPDATE_PROVIDERS_IMAGE = "/admin/uploadImage";
export const DELETE_PROVIDERS = "/provider";
export const SETTLE_PROVIDERS_PAYMENT = "/instructorTransaction";

// Location Api
export const GET_LOCATIONS = "/location";
export const ADD_NEW_LOCATIONS = "/location";
export const EDIT_LOCATIONS = "/location";
export const UPDATE_LOCATIONS = "/location";
export const UPDATE_LOCATIONS_IMAGE = "/admin/uploadImage";
export const DELETE_LOCATIONS = "/location";

// Holiday Api
export const GET_HOLIDAYS = "/holiday";
export const SAVE_HOLIDAY = "/holiday";
export const DELETE_HOLIDAY = "/holiday";
