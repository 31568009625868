import React, { useEffect, useState } from "react";
import {
  cilPencil,
  cilTrash,
  cilClone,
  cilUser,
  cilZoom,
  cilFilter,
} from "@coreui/icons";
import Image from "react-bootstrap/Image";

import { CCard, CCardBody, CCardGroup, CDateRangePicker } from "@coreui/react";
import DataTable from "react-data-table-component";
import { Row, Col, Label } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CIcon from "@coreui/icons-react";
import { useHistory, Link } from "react-router-dom";
import { toastConfirm } from "../../../common/toast";
import { deleteCast, getCast } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { setTokenHeader } from "../../../helpers/api_helper";
import NoImgFound from "../../../assets/No-image-found.jpg";
import { startLoader } from "../../../store/loaderState/actions";
import { deleteClasses, getClasses } from "../../../store/classes/actions";
import * as moment from "moment-timezone";
import axios from "axios";
import SelectAsyncPaginate from "../../../common/SelectAsyncPaginate";
import { Field } from "formik";
// import { DatePicker, LocalizationProvider} from "@mui/x-date-pickers"
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "bootstrap-daterangepicker/daterangepicker.css";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import { kDays } from "../../../constants/constants";

const Classes = () => {
  document.title = "Admin | All Classes";

  moment.tz.setDefault("Etc/UTC");

  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterData, setFilterData] = useState({
    search: "",
    sortBy: "",
    order: "",
    locationId: [],
    startDate: null,
  });
  console.log(filterData.startDate);
  console.log(filterData.endDate);
  const [showFilter, setShowFilter] = useState(false);
  const { classes } = useSelector((state) => state.Classes);
  const deleteClassWithId = async (id) => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire();
    if (response.isConfirmed) {
      dispatch(deleteClasses(id, history));
    }
  };

  const handleFilterState = (key, value) => {
    console.log(value);
    setFilterData((prev) => ({ ...prev, [key]: value }));
  };
  const handleMultiChange = (data) =>
    setFilterData((prev) => ({ ...prev, ...data }));

  const handleSelect = (event, picker) => {
    console.log(event);
    setFilterData({
      startDate: picker.startDate.toISOString(),
      endDate: picker.endDate.toISOString(),
    });
  };

  const formatDaysString = (days) => {
    if (!days) return "";

    var daysList = [];
    days.sort();
    for (let i = 0; i < days.length; i++) {
      daysList.push(kDays[days[i]]);
    }

    return daysList.join(", ");
  };

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));
    setTokenHeader(data.access_token);
    dispatch(startLoader());
    dispatch(getClasses());
  }, []);

  useEffect(() => {
    dispatch(getClasses(filterData));
  }, [filterData]);

  useEffect(() => {
    console.log("classes: ", classes);
  }, [classes]);

  const LoadLocations = async (searchQuery, loadedOptions, { page }) => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        JSON.parse(sessionStorage.getItem("authUser")).access_token
      }`;
      const responseJSON = await axios.get(
        `${process.env.REACT_APP_ALL_OUT_FTINESS_URL}/location?page=${page}`
      );
      console.log(responseJSON.data);
      return {
        options: responseJSON.data.list,
        // hasMore: responseJSON.length >= 1,
        hasMore: page < responseJSON.data.pagination.totalPages ? true : false,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } catch (error) {
      console.log("err : ", error);
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      };
    }
  };

  const customSort = (rows, selector, direction) => {
    handleMultiChange({
      sortBy: rows.actualKey,
      order: selector == "desc" ? -1 : 1,
    });
  };

  const columns = [
    {
      name: "Sr. no",
      selector: (row, index) => {
        if (classes?.pagination?.page - 1 != 0) {
          return (classes?.pagination?.page - 1) * 10 + (index + 1);
        }
        return index + 1;
      },
      sortable: true,
      width: "100px",
    },
    {
      name: "Image",
      selector: (row) => (
        <>
          {row?.classImage != null || row?.classImage != "" ? (
            <img
              src={row?.classImage}
              alt=""
              className="img-thumbnail "
              style={{ maxHeight: "200px" }}
            />
          ) : (
            <img
              src={NoImgFound}
              alt=""
              className="img-thumbnail "
              style={{ maxHeight: "200px" }}
            />
          )}
        </>
      ),
      sortable: true,
      actualKey: "classImage",
      width: "100px",
    },
    {
      name: "Class Name",
      selector: (row) => row.title,
      sortable: true,
      actualKey: "title",
      width: "250px",
    },
    {
      name: "Location",
      selector: (row) => row.location?.name,
      sortable: true,
      width: "250px",
    },
    {
      name: "Instructor",
      selector: (row) => row.instructor?.fname + " " + row.instructor?.lname,
      sortable: true,
    },
    {
      name: "Days",
      selector: (row) => formatDaysString(row.days),
      sortable: true,
      actualKey: "days",
    },
    {
      name: "Start Time",
      selector: (row) => moment(row.startTime).format("hh:mm a"),
      sortable: true,
      width: "170px",
    },
    {
      name: "Actions",
      width: "200px",
      selector: (row) => (
        <>
          <Link to={`classes/${row._id}/sessions`} className="actionIcons">
            <CIcon
              icon={cilZoom}
              className="text-success hand me-2"
              style={{ height: "20px !important" }}
              title="View Detail"
              onClick={() => {
                // toggleViewModal()
                // setEdit(row);
              }}
            />
          </Link>
          <Link to={`classes/${row._id}/clone`} className="actionIcons">
            <CIcon
              icon={cilClone}
              className="text-action hand me-2"
              title="Clone This Class"
              onClick={() => {
                // toggleViewModal()
                // setEdit(row);
              }}
            />
          </Link>
          <Link to={`classes/${row._id}/edit`}>
            <CIcon
              icon={cilPencil}
              className="text-warning hand me-2"
              title="Edit This Class"
              onClick={() => {
                // toggleViewModal()
                // setEdit(row);
              }}
            />
          </Link>

          <CIcon
            icon={cilTrash}
            className="text-danger hand"
            title="Delete This Class"
            onClick={() => {
              deleteClassWithId(row._id);
            }}
          />
        </>
      ),
    },
  ];
  const ResetFilters = () => {
    handleMultiChange({
      search: "",
      sortBy: "",
      order: "",
      categoryId: [],
      locationId: [],
      type: "",
      date: "",
      page: 1,
    });
    // setFilterData({})
  };

  return (
    <React.Fragment>
      <div className="page-content cast ">
        <div className="container-fluid">
          <Breadcrumbs title="All Classes" breadcrumbItem="Manage Class" />

          <Row>
            <Col xl={12}>
              <div className="mb-2">
                <Link to={"/all-classes/add"} className="btn btn-primary w-md">
                  Add +
                </Link>
              </div>
              <CCardGroup className="mb-2">
                <CCard>
                  <CCardBody>
                    <div className="row">
                      <div className="offset-md-6 col-md-6 d-flex justify-content-right">
                        <div className="w-75">
                          <label>Search :</label>
                          <input
                            type="text"
                            className="form-control"
                            id="search"
                            name="search"
                            placeholder="Search"
                            value={filterData?.search}
                            onChange={(e) => {
                              handleFilterState("search", e.target.value);
                            }}
                          />
                        </div>
                        <div className="w-25 mt-4 d-flex justify-content-center align-items-center">
                          <button
                            className="border text-success p-2"
                            onClick={() => {
                              setShowFilter(
                                (prevShowFilter) => !prevShowFilter
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              border: "1px solid #556ee6",
                              padding: "6px",
                              borderRadius: "3px",
                            }}
                          >
                            <label className="mb-0">More Filters</label>

                            <CIcon
                              icon={cilFilter}
                              className="text-primary hand searchFilter"
                              style={{
                                height: "15px !important",
                                cursor: "pointer",
                              }}
                              title="View Detail"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`row mt-2 ${
                        showFilter == true ? "" : "d-none"
                      }`}
                    >
                      <div className="col-md-6 mb-2 position-relative ">
                        <label>Select Location:</label>
                        <SelectAsyncPaginate
                          name="location"
                          // regionName={region.value}
                          loadOptions={LoadLocations}
                          label={"Location"}
                          isMulti={true}
                          value={filterData?.locationId}
                          onChange={(location) => {
                            handleFilterState("locationId", location);
                          }}
                        />
                      </div>

                      <div className="col-md-6">
                        <label>Select Date:</label>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        type="date"
                                                        className="form-control"
                                                        id="date"
                                                        name="date"
                                                        onChange={newValue => {
                                                             handleFilterState('date',newValue.$d.toISOString())
                                                           
                                                        }}
                                                    />
                                                </LocalizationProvider> */}
                        <div>
                          <DateRangePicker
                            startDate={filterData.startDate}
                            endDate={filterData.endDate}
                            onApply={handleSelect}
                          >
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Please select a date"
                            />
                          </DateRangePicker>
                        </div>
                      </div>

                      {/* <div className="col-md-6">
                        <label>Class Type:</label>

                        <SelectAsyncPaginate
                          name="type"
                          // regionName={region.value}
                          loadOptions={loadTypesFn}
                          label="Type"
                          isMulti={false}
                          value={filterData.type}
                          onChange={(type) => {
                            handleFilterState("type", type);
                          }}
                        />
                      </div> */}
                      <div className="col-md-12 mt-2">
                        <input
                          type="button"
                          className="btn btn-primary"
                          id="search"
                          value="Reset"
                          onClick={() => ResetFilters()}
                        />
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>

              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <DataTable
                      striped
                      onSort={customSort}
                      columns={columns}
                      sortServer
                      data={classes?.data}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={classes?.pagination?.total}
                      paginationPerPage={countPerPage}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      onChangePage={(page, totalRows) => {
                        handleFilterState("page", page);
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Classes;
