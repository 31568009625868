import React, { useEffect, useState } from "react";
import { cilZoom } from "@coreui/icons";
import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";

import NoImgFound from "../../../assets/No-image-found.jpg";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Label,
} from "reactstrap";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTokenHeader } from "../../../helpers/api_helper";
import { editClasses, addSession } from "../../../store/classes/actions";
import { startLoader, stopLoader } from "../../../store/actions";
import { toast } from "react-toastify";
import axios from "axios";
import SelectAsyncPaginate from "../../../common/SelectAsyncPaginate";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as moment from "moment-timezone";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UTCDatePicker from "../../../common/utcDatePicker";
import { API_URL } from "../../../helpers/api_helper";

const SessionAdd = () => {
  moment.tz.setDefault("Etc/UTC");
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { singleClass } = useSelector((state) => state.Classes);

  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [data, setData] = useState({
    date: "",
    instructor: {},
  });

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));
    setTokenHeader(data.access_token);
    dispatch(stopLoader());

    dispatch(editClasses(params.id));
  }, []);

  useEffect(() => {
    console.log("singleClass : ", singleClass);
  }, [singleClass]);

  //meta title
  document.title = "Add Session";

  const addSessionValidation = Yup.object().shape({
    date: Yup.string().required("Date is Required"),
  });

  const handleSubmit = (e) => {
    console.log("handleSubmit data", data);

    dispatch(startLoader());
    dispatch(addSession(data, history, singleClass._id));
  };

  const LoadInstructors = async (searchQuery, loadedOptions, { page }) => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        JSON.parse(sessionStorage.getItem("authUser")).access_token
      }`;
      const responseJSON = await axios.get(`${API_URL}/provider?page=${page}`);
      console.log("providers", responseJSON.data);
      return {
        options: responseJSON.data.list,
        // hasMore: responseJSON.length >= 1,
        hasMore: page < responseJSON.data.pagination.totalPages ? true : false,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      };
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={singleClass?.title}
            breadcrumbItem={"Add Session"}
          />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft"></div>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <div className="  mb-4"></div>
                      <h5 className="font-size-15 text-truncate text-center">
                        {singleClass?.title} Class
                      </h5>
                    </Col>

                    <Col sm={12} className="text-center">
                      {singleClass?.classImage != null ||
                      singleClass?.classImage != "" ? (
                        <img
                          src={singleClass?.classImage}
                          alt=""
                          className="img-thumbnail "
                          style={{ maxHeight: "200px" }}
                        />
                      ) : (
                        <img
                          src={NoImgFound}
                          alt=""
                          className="img-thumbnail "
                          style={{ maxHeight: "200px" }}
                        />
                      )}
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Location :</th>
                          <td>{singleClass?.location?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Start Time :</th>
                          <td>
                            {moment(singleClass?.startTime).format("hh:mm a")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Duration :</th>
                          <td>{singleClass?.duration} min</td>
                        </tr>
                        <tr>
                          <th scope="row">Session Price :</th>
                          <td>${singleClass?.price}</td>
                        </tr>
                        <tr>
                          <th scope="row">Total Seats :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.totalSeat}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">First Session Date :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.firstSessionDate
                              ? moment(singleClass.firstSessionDate).format(
                                  "ddd, MMM DD"
                                )
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">End by Date :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.endByDate
                              ? moment(singleClass.endByDate).format(
                                  "ddd, MMM DD"
                                )
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Limit Number of Sessions :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.limitNumberOfSessions || "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4 h4">Instructor Detail</CardTitle>

                  <p className="text-muted mb-4">
                    {singleClass?.instructor?.personalDetail}
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <td colSpan={2} className="text-center">
                            {singleClass?.instructor?.userProfile != null ||
                            singleClass?.instructor?.userProfile != "" ? (
                              <img
                                src={singleClass?.instructor?.userProfile}
                                alt=""
                                className="img-thumbnail "
                                style={{ maxHeight: "200px" }}
                              />
                            ) : (
                              <img
                                src={NoImgFound}
                                alt=""
                                className="img-thumbnail "
                                style={{ maxHeight: "200px" }}
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{singleClass?.instructor?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{singleClass?.instructor?.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{singleClass?.instructor?.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Session Rate :</th>
                          <td>${singleClass?.instructor?.sessionRate}</td>
                        </tr>
                        <tr>
                          <th scope="row">Gender :</th>
                          <td>
                            {singleClass?.instructor?.gender == 0
                              ? "Male"
                              : "Female"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Formik
                initialValues={{ ...data }}
                validationSchema={addSessionValidation}
                enableReinitialize
                onSubmit={(e) => {
                  console.log("onSubmit data", data);
                  handleSubmit();
                  return false;
                }}
              >
                {({ values, setValues, setFieldValue, errors, touched }) => (
                  console.log("errors", errors),
                  (
                    <Card>
                      <CardBody>
                        <Form>
                          <CardTitle className="mb-4 h4">Add Session</CardTitle>
                          <div className="mb-3">
                            <div className="col-md-12 d-flex flex-column">
                              <Label htmlFor="formrow-firstname-Input">
                                Session Date
                              </Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <UTCDatePicker
                                  type="date"
                                  className="form-control"
                                  id="date"
                                  name="date"
                                  onChange={(newValue) => {
                                    console.log("new date: ", newValue);
                                    setData({
                                      ...data,
                                      date: newValue,
                                    });
                                    setFieldValue("date", newValue);
                                  }}
                                />
                              </LocalizationProvider>
                              {errors.date && touched.date ? (
                                <div className="text-danger input-error">
                                  {errors.date}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Guest Instructor <small>( Optional )</small>
                            </Label>
                            <SelectAsyncPaginate
                              name="instructor"
                              // regionName={region.value}
                              loadOptions={LoadInstructors}
                              placeHolder="Select Instructor"
                              isMulti={false}
                              value={data.instructor}
                              onChange={(instructor) => {
                                setData({ ...data, instructor });
                                console.log(instructor);
                              }}
                            />
                          </div>
                          <div className="pt-5">
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Submit
                            </button>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  )
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SessionAdd;
