import { call, put, takeEvery} from "redux-saga/effects";

// Crypto Redux States
import { GET_TRANSACTIONS, ADD_TRANSACTIONS, DELETE_TRANSACTIONS, EDIT_TRANSACTIONS, UPDATE_TRANSACTIONS, GET_USER_TRANSACTIONS } from "./actionTypes";
import { getTransactionsFail, getTransactionsSuccess, getUserTransactionsFail, getUserTransactionsSuccess,addTransactionsFail, addTransactionsSuccess, deleteTransactionsSuccess, deleteTransactionsFail, editTransactionsSuccess, editTransactionsFail, updateTransactionsSuccess, updateTransactionsFail } from "./actions";

//Include Both Helper File with needed methods
import {
    getTransactionsApi,
    addNewTransactionsApi,
    deleteTransactionsApi,
    editTransactionsApi,
    updateTransactionsApi,
    getUserTransactionsApi
}
    from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { stopLoader } from "../loaderState/actions";

function* getTransactionsData({ payload: page }) {
    try {

        let response = yield call(getTransactionsApi, page.page);
        yield put(getTransactionsSuccess(response));
    } catch (error) {
        yield put(getTransactionsFail(error));
    }
    yield put(stopLoader())

}

function* getUserTransactionsData({ payload: page }) {
    try {

        let response = yield call(getUserTransactionsApi, page.page);
        yield put(getTransactionsSuccess(response));
    } catch (error) {
        yield put(getTransactionsFail(error));
    }
    yield put(stopLoader())

}

function* addTransactionsData({ payload: { data, history } }) {
    try {

        let response = yield call(addNewTransactionsApi, data);
        if(response.status ==200){
            toast.success('Updated Successfully.')
        }else{
            toast.error(response.message)
        }

    } catch (error) {
        yield put(getTransactionsFail(error));
    }
    yield put(stopLoader())

}


function* editTransactions({ payload: id }) {
    try {

        let response = yield call(editTransactionsApi, id.id);
        console.log(response);
        yield put(editTransactionsSuccess(response));
    } catch (error) {
        yield put(editTransactionsFail(error));
    }
    yield put(stopLoader())

}

function* onUpdateTransactions({ payload: data }) {
    try {
        console.log(data)
        const response = yield call(addNewTransactionsApi,data)
        if(response.status ==200){
            toast.success('Updated Successfully.')
        }else{
            toast.error(response.message)
        }
    } catch (error) {
        yield put(updateTransactionsFail(error))
    }
    yield put(stopLoader())

}

function* deleteTransactions({ payload: id }) {
    try {
        const response = yield call(deleteTransactionsApi, id.id)
        if(response.status ==201){
        yield put(deleteTransactionsSuccess(id))
        // yield put(getTransactions());
        }else{
            toast.error(response.message)
        }

    } catch (error) {
        yield put(deleteTransactionsFail(error))
    }
    yield put(stopLoader())

}

function* TransactionsSaga() {
    yield takeEvery(GET_TRANSACTIONS, getTransactionsData);
    yield takeEvery(GET_USER_TRANSACTIONS, getUserTransactionsData);
    yield takeEvery(ADD_TRANSACTIONS, addTransactionsData);
    yield takeEvery(EDIT_TRANSACTIONS, editTransactions);
    yield takeEvery(UPDATE_TRANSACTIONS, onUpdateTransactions)
    yield takeEvery(DELETE_TRANSACTIONS, deleteTransactions);
}


export default TransactionsSaga;
