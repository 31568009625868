import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
// import Spinner from "loader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { addSplashScreen, updateSplashScreen } from "../../../../store/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import cloud from "../../../../assets/flix-images/cloud-file-download.svg";
import Dropzone from "react-dropzone";
import { fireToast } from "../../../../common/toast";

const EditSplashScreenList = (props) => {
  const uploadRef = useRef(null);
  const dispatch = useDispatch();
  let history = useHistory();
  const { isOpen, toggle, editId, label } = props;
  const [data, setData] = useState({});
  const [close, setClose] = useState(true);

  const handleClose = () => {
    setClose(toggle);
  };

  const [image, setImage] = useState({
    blob: null,
    src: "",
  });

  const stepAddSchema = Yup.object().shape({
    image: Yup.mixed().required("File is required"),
  });

  const handleAddSplashScreen = async (values) => {
    console.log(data);

    dispatch(updateSplashScreen(editId, data, handleClose));
  };

  useEffect(() => {
    console.log("editId :", editId);
  }, [editId]);
  useEffect(() => {
    console.log("label :", label);
  }, [label]);

  const handleImageChange = (files) => {
    const extn = ["image/jpg", "image/png", "image/jpeg"];
    const [file] = files;
    setData({ ...data, file });

    if (file && extn.includes(file.type)) {
      if (file.size <= 5242880) {
        setImage({ blob: file, src: window.URL.createObjectURL(file) });
      } else {
        fireToast("error", "image too large");
      }
    } else {
      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal bs-example-modal" role="dialog"></div>
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Edit </ModalHeader>
        <Formik
          initialValues={{
            name: "",
            description: "",
          }}
          validationSchema={stepAddSchema}
          onSubmit={(values, actions) => {
            handleAddSplashScreen(values);
          }}
        >
          {({ values, setValues, setFieldValue, errors, touched }) => (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleAddSplashScreen();
                return false;
              }}
            >
              <ModalBody>
                <div>
                  <Label htmlFor="formrow-firstname-Input">Image</Label>
                  <div className="text-center">
                    <div className="text-center">
                      <div className="mb-3 dragdrop-container">
                        <input
                          ref={uploadRef}
                          id="upload"
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => handleImageChange(e.target.files)}
                        />
                        {image.src ? (
                          <div className="commonImgs">
                            <img
                              className="every-img"
                              src={image.src ? image.src : cloud}
                              alt=""
                              onClick={() => {
                                uploadRef.current.click();
                              }}
                            />
                          </div>
                        ) : (
                          <div className="drag-n-drop-container">
                            <div>
                              <Dropzone
                                accept="image/*"
                                multiple={false}
                                onDrop={(acceptedFiles) => {
                                  handleImageChange(acceptedFiles);
                                }}
                              >
                                {({
                                  getRootProps,
                                  getInputProps,
                                  isDragActive,
                                }) => (
                                  <section>
                                    <div
                                      className="drop-area"
                                      {...getRootProps()}
                                    >
                                      <img width={60} src={cloud} alt="" />
                                      <input
                                        {...getInputProps()}
                                        accept="image/*"
                                        multiple={false}
                                      />

                                      {isDragActive ? (
                                        <div>Drop your image file here</div>
                                      ) : (
                                        <p>
                                          Drag n drop image file here, or click
                                          to select <br />
                                          <small className="text-center">
                                            <strong>Supported files:</strong>{" "}
                                            jpeg, jpg, png. | Will be resized
                                            to: 1920x1080 px.
                                          </small>
                                        </p>
                                      )}
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="fw-bold">
                      Note:
                      <span className="text-danger mx-2 text-align-left">
                        Supported image formats are:&nbsp;jpg, png and jpeg only
                      </span>
                    </p>
                  </div>
                  <div>
                    <Label htmlFor="formrow-firstname-Input">Image Label</Label>
                    <Field
                      type="text"
                      className="form-control"
                      id="label"
                      name="label"
                      defaultValue={label}
                      onChange={(e) => {
                        setData({ ...data, label: e.target.value });
                        setFieldValue("label", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Save Changes
                </Button>
                <Button type="button" color="secondary" onClick={toggle}>
                  Close
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

EditSplashScreenList.propTypes = {
  toggle: PropTypes.func,
  changeApiStatus: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default EditSplashScreenList;
