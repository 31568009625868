import React, { useEffect, useState } from "react"
import { cilPencil, cilTrash } from "@coreui/icons"

import { CCard, CCardBody, CCardGroup } from "@coreui/react"
import DataTable from "react-data-table-component"
import {
    Row,
    Col,
} from "reactstrap"
import NoImgFound from "../../../assets/No-image-found.jpg"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CIcon from "@coreui/icons-react"
import { useHistory, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setTokenHeader } from "../../../helpers/api_helper"
import { startLoader } from "../../../store/loaderState/actions";
import {getUserTransactions } from "../../../store/transactions/actions"
import { Image } from "react-bootstrap"
import moment from "moment"

const UserTransaction = () => {
    document.title = "User Transactions";
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    const dispatch = useDispatch();
    const { transactions } = useSelector(state => state.Transactions)


    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem("authUser"))
        setTokenHeader(data.access_token)
        dispatch(startLoader());
        dispatch(getUserTransactions())
    }, []);

    useEffect(() => {
        dispatch(startLoader());
        dispatch(getUserTransactions(page))
        console.log('transactions : ' , transactions)
    }, [page]);

    const columns = [
        // {
        //     name: "Sr. no",
        //     selector: (row,index) => {
        //         if((transactions?.pagination?.page - 1) !=0){
                   
        //              return ((transactions?.pagination?.page-1)*10) + (index+1)
        //         }
        //         return index+1
        //     },
        //     sortable: true,
        // },
        {
            name: "OrderId",
            selector: row => `#${row.orderId?.orderNo}`,
            sortable: true,
        },
        {
            name: "Name",
            selector: row => `${row.userId?.fname} ${row.userId?.lname}`,
            sortable: true,
        },
        {
            name: "User Profile",
            selector: row => row.userId?.userProfile!= null ? (<Image
                fluid={true}
                src={`${process.env.REACT_APP_ASSET_URL}${row.userId?.userProfile}`}
                className="img-fluid thumbnail my-2 round"
                style={{maxHeight:'50px'}}
              />) :(<Image
                fluid={true}
                style={{maxHeight:'50px'}}
                src={NoImgFound}
              />),
            sortable: true,
        },
        {
            name: "Email",
            selector: row => row.userId?.email,
            sortable: true,
        },
        {
            name: "Amount",
            selector: row => `$${row.amount}`,
            sortable: true,
        },
        {
            name: "Date",
            selector: row => moment(row.orderId?.createdAt).format('MM/DD/YYYY'),
            sortable: true,
        },
        {
            name: "Message",
            selector: row => row.message,
            sortable: true,
        },

        

    ]

    return (
        <React.Fragment>
            <div className="page-content cast ">
                <div className="container-fluid">
                    <Breadcrumbs title="Transactions" breadcrumbItem="User Transactions" />

                    <Row>
                        <Col xl={12}>
                            <CCardGroup>
                                <CCard>
                                    <CCardBody>
                                        <DataTable
                                            striped
                                            columns={columns}
                                            data={transactions?.data}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            keyField={'keyField'}
                                            paginationTotalRows={transactions?.pagination?.total}
                                            paginationPerPage={countPerPage}
                                            paginationComponentOptions={{
                                                noRowsPerPage: true
                                            }}
                                            onChangePage={(page, totalRows) => {
                                                console.log('page : ', page);
                                                console.log('totalRows : ', totalRows);
                                                setPage(page)
                                            }}
                                        />
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>

                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UserTransaction
