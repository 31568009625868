import {
  GET_LOCATIONS,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAIL,
  ADD_LOCATIONS,
  ADD_LOCATIONS_SUCCESS,
  ADD_LOCATIONS_FAIL,
  DELETE_LOCATIONS,
  DELETE_LOCATIONS_SUCCESS,
  DELETE_LOCATIONS_FAIL,
  EDIT_LOCATIONS_SUCCESS,
  EDIT_LOCATIONS,
  EDIT_LOCATIONS_FAIL,
  UPDATE_LOCATIONS_SUCCESS,
  UPDATE_LOCATIONS,
  UPDATE_LOCATIONS_FAIL,
} from "./actionTypes";

export const getLocations = (page = 1, searchKeyword = "") => ({
  type: GET_LOCATIONS,
  payload: { page, searchKeyword },
});

export const getLocationsSuccess = (data) => ({
  type: GET_LOCATIONS_SUCCESS,
  payload: { data },
});

export const getLocationsFail = (error) => ({
  type: GET_LOCATIONS_FAIL,
  payload: { error },
});

export const addLocations = (reqData, history) => ({
  type: ADD_LOCATIONS,
  payload: { reqData, history },
});

export const addLocationsSuccess = (data) => ({
  type: ADD_LOCATIONS_SUCCESS,
  payload: { data },
});

export const addLocationsFail = (error) => ({
  type: ADD_LOCATIONS_FAIL,
  payload: { error },
});

export const deleteLocations = (id) => ({
  type: DELETE_LOCATIONS,
  payload: { id },
});

export const deleteLocationsSuccess = (data) => ({
  type: DELETE_LOCATIONS_SUCCESS,
  payload: { data },
});

export const deleteLocationsFail = (error) => ({
  type: DELETE_LOCATIONS_FAIL,
  payload: { error },
});
export const editLocations = (id, history) => ({
  type: EDIT_LOCATIONS,
  payload: { id, history },
});

export const editLocationsSuccess = (data) => ({
  type: EDIT_LOCATIONS_SUCCESS,
  payload: { data },
});

export const editLocationsFail = (error) => ({
  type: EDIT_LOCATIONS_FAIL,
  payload: { error },
});

export const updateLocationsSuccess = (location) => ({
  type: UPDATE_LOCATIONS_SUCCESS,
  payload: location,
});

export const updateLocations = (reqData, history) => ({
  type: UPDATE_LOCATIONS,
  payload: { reqData, history },
});
export const updateLocationsFail = (error) => ({
  type: UPDATE_LOCATIONS_FAIL,
  payload: error,
});
