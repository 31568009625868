import {
  GET_HOLIDAYS,
  GET_HOLIDAYS_SUCCESS,
  GET_HOLIDAYS_FAIL,
  SAVE_HOLIDAY,
  SAVE_HOLIDAY_SUCCESS,
  SAVE_HOLIDAY_FAIL,
  DELETE_HOLIDAY,
  DELETE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_FAIL,
} from "./actionTypes";

export const getHolidays = (startDate, endDate) => ({
  type: GET_HOLIDAYS,
  payload: { startDate, endDate },
});

export const getHolidaysSuccess = (data) => ({
  type: GET_HOLIDAYS_SUCCESS,
  payload: { data },
});

export const getHolidaysFail = (error) => ({
  type: GET_HOLIDAYS_FAIL,
  payload: { error },
});

export const saveHoliday = (data) => ({
  type: SAVE_HOLIDAY,
  payload: { data },
});

export const saveHolidaySuccess = (data) => ({
  type: SAVE_HOLIDAY_SUCCESS,
  payload: { data },
});

export const saveHolidayFail = (error) => ({
  type: SAVE_HOLIDAY_FAIL,
  payload: { error },
});

export const deleteHoliday = (id) => ({
  type: DELETE_HOLIDAY,
  payload: { id },
});

export const deleteHolidaySuccess = () => ({
  type: DELETE_HOLIDAY_SUCCESS,
  payload: {},
});

export const deleteHolidayFail = (error) => ({
  type: DELETE_HOLIDAY_FAIL,
  payload: { error },
});
