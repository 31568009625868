import React, { useEffect, useRef, useState } from "react";
import * as moment from "moment-timezone";
import { Card, Col, Container, Row, CardBody, Label } from "reactstrap";
import * as Yup from "yup";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Field, Formik, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { setTokenHeader } from "../../../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { editClasses, updateClasses } from "../../../store/classes/actions";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import axios from "axios";
import SelectAsyncPaginate from "../../../common/SelectAsyncPaginate";
import { fireToast } from "../../../common/toast";
import cloud from "../../../assets/flix-images/cloud-file-download.svg";
import Dropzone from "react-dropzone";
import { startLoader } from "../../../store/actions";
import { DatePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import { API_URL } from "../../../helpers/api_helper";

const ClassesEdit = (props) => {
  //meta title
  document.title = "Edit Class";

  moment.tz.setDefault("Etc/UTC");
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { singleClass } = useSelector((state) => state.Classes);

  const uploadRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [data, setData] = useState({
    title: "",
    startTime: "",
    firstSessionDate: null,
    endByDate: null,
    limitNumberOfSessions: null,
    location: {},
    description: "",
    price: "",
    minCancelWindow: null,
    isPublic: false,
    isOutdoor: false,
    file: "",
    duration: 0,
    totalSeat: 1,
    instructor: {},
    classImage: null,
    days: [],
    mon: false,
    tue: false,
    wed: false,
    thur: false,
    fri: false,
    sat: false,
    sun: false,
  });

  const [image, setImage] = useState({
    blob: null,
    src: "",
  });
  const handleImageChange = (files) => {
    const extn = ["image/jpg", "image/png", "image/jpeg"];
    const [file] = files;

    if (file && extn.includes(file.type)) {
      if (file.size <= 5242880) {
        setImage({ blob: file, src: window.URL.createObjectURL(file) });
        setData({ ...data, file });
      } else {
        fireToast("error", "image too large");
      }
    } else {
      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      );
    }
  };

  const UploadFile = (file = "") => {
    if (!file || file === "") {
      return null;
    }
    return new Promise(async (resolve, reject) => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        JSON.parse(sessionStorage.getItem("authUser")).access_token
      }`;
      axios
        .post(
          `${API_URL}/uploadClassImage`,
          { file },
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then(function (response) {
          const imageUrl = response.data.data;
          console.log("response image url: ", imageUrl);
          resolve(imageUrl);
        })
        .catch(function (error) {
          console.log("error : ", error.response.message);
          reject(error);
        });
    });
  };

  const EditClass = Yup.object().shape({
    title: Yup.string()
      .min(4, "Too short!!")
      .max(1000, "Too Long!!")
      .required("This field is required"),
    startTime: Yup.date().required("This field is required"),
    description: Yup.string().required("This field is required"),
    price: Yup.string().required("This field is required"),
    duration: Yup.string().required("This field is required"),
    location: Yup.object().required("This field is required"),
    instructor: Yup.object().required("This field is required"),
    totalSeat: Yup.string().required("This field is required"),
    tue: Yup.boolean(),
  });

  const handleSubmit = async (e) => {
    if (Object.keys(data.instructor).length == 0) {
      toast.error("Instructor feild is Required.");
      return false;
    }
    if (Object.keys(data.location).length == 0) {
      toast.error("Location feild is Required.");
      return false;
    }

    dispatch(startLoader());
    data.classImage = await UploadFile(image.blob);
    dispatch(updateClasses(params.id, data, history));
  };

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));

    setTokenHeader(data.access_token);
    dispatch(editClasses(params.id));
  }, []);

  const processTime = (date) => {
    console.log(date);
    let Date = date.$d;
    Date = Date.toUTCString();
    console.log(Date);
    return Date;
  };

  useEffect(() => {
    console.log("updating data from singleClass", singleClass);
    if (singleClass) {
      setData({
        ...singleClass,
        title: singleClass?.title,
        description: singleClass?.description,
        startTime: singleClass?.startTime,
        firstSessionDate: singleClass?.firstSessionDate,
        endByDate: singleClass?.endByDate,
        limitNumberOfSessions: singleClass?.limitNumberOfSessions,
        duration: singleClass?.duration,
        price: singleClass?.price,
        minCancelWindow: singleClass?.minCancelWindow,
        isPublic: singleClass?.isPublic,
        isOutdoor: singleClass?.isOutdoor,
        location: singleClass?.location,
        instructor: singleClass?.instructor,
        totalSeat: singleClass?.totalSeat,
        classImage: singleClass?.classImage,
        days: singleClass?.days,
        mon: singleClass?.days?.includes(0),
        tue: singleClass?.days?.includes(1),
        wed: singleClass?.days?.includes(2),
        thur: singleClass?.days?.includes(3),
        fri: singleClass?.days?.includes(4),
        sat: singleClass?.days?.includes(5),
        sun: singleClass?.days?.includes(6),
      });
    }
  }, [singleClass]);

  function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }
  const LoadLocations = async (searchQuery, loadedOptions, { page }) => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        JSON.parse(sessionStorage.getItem("authUser")).access_token
      }`;
      const responseJSON = await axios.get(
        `${process.env.REACT_APP_ALL_OUT_FTINESS_URL}/location?page=${page}`
      );
      console.log(responseJSON.data);
      return {
        options: responseJSON.data.list,
        // hasMore: responseJSON.length >= 1,
        hasMore: page < responseJSON.data.pagination.totalPages ? true : false,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } catch (error) {
      console.log("err : ", error);
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      };
    }
  };

  const LoadInstructors = async (searchQuery, loadedOptions, { page }) => {
    try {
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        JSON.parse(sessionStorage.getItem("authUser")).access_token
      }`;
      const responseJSON = await axios.get(`${API_URL}/provider?page=${page}`);

      console.log("LoadInstructors responseJSON", responseJSON);

      return {
        options: responseJSON.data.list,
        // hasMore: responseJSON.length >= 1,
        hasMore: page < responseJSON.data.pagination.totalPages ? true : false,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      };
    }
  };

  function updateDays(dayIndex, isIncluded) {
    console.log("before change days", data.days);
    console.log("dayIndex, isIncluded", dayIndex, isIncluded);
    var days = data.days || [];
    if (isIncluded) {
      if (days.includes(dayIndex)) {
        return;
      }

      console.log("pushing", dayIndex);
      days.push(dayIndex);
    } else {
      console.log("removing from", dayIndex, days);
      days = data.days.filter((day) => day != dayIndex);
      console.log("removed", dayIndex, days);
    }

    console.log("updated days", days);
    return days;
  }
  useEffect(() => {
    console.log("saved days", data.days);
  }, [data]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Class" breadcrumbItem="Edit Class" />
          <Formik
            initialValues={{ ...data }}
            validationSchema={EditClass}
            enableReinitialize
            onSubmit={(e) => {
              handleSubmit();
              return false;
            }}
          >
            {({ values, setValues, setFieldValue, errors, touched }) => (
              <Row>
                <Col xl={8}>
                  <Card>
                    <CardBody>
                      <Form>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Instructor
                          </Label>
                          <SelectAsyncPaginate
                            name="instructor"
                            loadOptions={LoadInstructors}
                            placeHolder="Select Instructor"
                            isMulti={false}
                            value={data.instructor}
                            onChange={(instructor) => {
                              setData({ ...data, instructor });
                              console.log(instructor);
                              console.log(data);
                            }}
                          />
                          {errors.instructor && touched.instructor ? (
                            <div className="text-danger input-error">
                              {errors.instructor}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Title</Label>
                          <Field
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            onChange={(e) => {
                              setData({ ...data, title: e.target.value });
                              setFieldValue("title", e.target.value);
                              console.log(data);
                            }}
                          />
                          {errors.title && touched.title ? (
                            <div className="text-danger input-error">
                              {errors.title}
                            </div>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-6 d-flex flex-column">
                              <Label htmlFor="formrow-firstname-Input">
                                Start Time
                              </Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  value={dayjs.utc(data?.startTime)}
                                  ClassName="form-control"
                                  name="startTime"
                                  onChange={(newValue) => {
                                    console.log("raw incoming", newValue.$d);
                                    var time = processTime(newValue);
                                    setData({ ...data, startTime: time });
                                  }}
                                />
                              </LocalizationProvider>
                              {errors.startTime && touched.startTime ? (
                                <div className="text-danger input-error">
                                  {errors.startTime}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 d-flex flex-column">
                              <Label htmlFor="formrow-firstname-Input">
                                Duration <small>( In minutes )</small>
                              </Label>
                              <Field
                                type="text"
                                className="form-control"
                                id="duration"
                                name="duration"
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    duration: e.target.value,
                                  });
                                  setFieldValue("duration", e.target.value);
                                }}
                              />
                              {errors.duration && touched.duration ? (
                                <div className="text-danger input-error">
                                  {errors.duration}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Days</Label>
                          <br />
                          <Field
                            id="mon"
                            type="checkbox"
                            name="mon"
                            onChange={(e) => {
                              setData({
                                ...data,
                                mon: e.target.checked,
                                days: updateDays(0, e.target.checked),
                              });
                              setFieldValue("mon", e.target.checked);
                            }}
                          />
                          &nbsp; &nbsp;
                          <label htmlFor="mon">Mon</label>
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          <Field
                            id="tue"
                            type="checkbox"
                            name="tue"
                            onChange={(e) => {
                              setData({
                                ...data,
                                tue: e.target.checked,
                                days: updateDays(1, e.target.checked),
                              });
                              setFieldValue("tue", e.target.checked);
                            }}
                          />
                          &nbsp; &nbsp;
                          <label htmlFor="tue">Tue</label>
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          <Field
                            id="wed"
                            type="checkbox"
                            name="wed"
                            onChange={(e) => {
                              setData({
                                ...data,
                                wed: e.target.checked,
                                days: updateDays(2, e.target.checked),
                              });
                              setFieldValue("wed", e.target.checked);
                            }}
                          />
                          &nbsp; &nbsp;
                          <label htmlFor="wed">Wed</label>
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          <Field
                            id="thur"
                            type="checkbox"
                            name="thur"
                            onChange={(e) => {
                              setData({
                                ...data,
                                thur: e.target.checked,
                                days: updateDays(3, e.target.checked),
                              });
                              setFieldValue("thur", e.target.checked);
                            }}
                          />
                          &nbsp; &nbsp;
                          <label htmlFor="thur">Thur</label>
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          <Field
                            id="fri"
                            type="checkbox"
                            name="fri"
                            onChange={(e) => {
                              setData({
                                ...data,
                                fri: e.target.checked,
                                days: updateDays(4, e.target.checked),
                              });
                              setFieldValue("fri", e.target.checked);
                            }}
                          />
                          &nbsp; &nbsp;
                          <label htmlFor="fri">Fri</label>
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          <Field
                            id="sat"
                            type="checkbox"
                            name="sat"
                            initialValues={true}
                            onChange={(e) => {
                              setData({
                                ...data,
                                sat: e.target.checked,
                                days: updateDays(5, e.target.checked),
                              });
                              setFieldValue("sat", e.target.checked);
                            }}
                          />
                          &nbsp; &nbsp;
                          <label htmlFor="sat">Sat</label>
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          <Field
                            id="sun"
                            type="checkbox"
                            name="sun"
                            onChange={(e) => {
                              setData({
                                ...data,
                                sun: e.target.checked,
                                days: updateDays(6, e.target.checked),
                              });
                              setFieldValue("sun", e.target.checked);
                            }}
                          />
                          &nbsp; &nbsp;
                          <label htmlFor="sun">Sun</label>
                        </div>

                        <div className="mb-3">
                          <div className="row">
                            <div className="col-md-4 d-flex flex-column">
                              <Label htmlFor="formrow-firstname-Input">
                                First Session Date <small>( Optional )</small>
                              </Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  type="date"
                                  value={
                                    data?.firstSessionDate
                                      ? dayjs.utc(data?.firstSessionDate)
                                      : null
                                  }
                                  className="form-control"
                                  id="firstSessionDate"
                                  name="firstSessionDate"
                                  onChange={(newValue) => {
                                    setData({
                                      ...data,
                                      firstSessionDate: newValue?.$d,
                                    });
                                    setFieldValue(
                                      "firstSessionDate",
                                      newValue?.$d
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                              <Label htmlFor="formrow-firstname-Input">
                                End By Date <small>( Optional )</small>
                              </Label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  type="date"
                                  value={
                                    data?.endByDate
                                      ? dayjs.utc(data?.endByDate)
                                      : null
                                  }
                                  className="form-control"
                                  id="endByDate"
                                  name="endByDate"
                                  onChange={(newValue) => {
                                    setData({
                                      ...data,
                                      endByDate: newValue?.$d,
                                    });
                                    setFieldValue("endByDate", newValue?.$d);
                                  }}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                              <Label htmlFor="formrow-firstname-Input">
                                Limit Number of Sessions{" "}
                                <small>( Optional )</small>
                              </Label>
                              <Field
                                type="number"
                                value={data?.limitNumberOfSessions}
                                className="form-control"
                                id="limitNumberOfSessions"
                                name="limitNumberOfSessions"
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    limitNumberOfSessions: e.target.value,
                                  });
                                  setFieldValue(
                                    "limitNumberOfSessions",
                                    e.target.value
                                  );
                                }}
                              />
                              {errors.duration && touched.duration ? (
                                <div className="text-danger input-error">
                                  {errors.duration}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Description
                          </Label>
                          <Field
                            as="textarea"
                            className="form-control"
                            id="description"
                            name="description"
                            rows="5"
                            onChange={(e) => {
                              setData({ ...data, description: e.target.value });
                              setFieldValue("description", e.target.value);
                              console.log(data);
                            }}
                          />
                          {errors.description && touched.description ? (
                            <div className="text-danger input-error">
                              {errors.description}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Field
                            id="isPublic"
                            type="checkbox"
                            name="isPublic"
                            initialValues={data?.isPublic}
                            onChange={(e) => {
                              setData({
                                ...data,
                                isPublic: e.target.checked,
                              });
                              setFieldValue("isPublic", e.target.checked);
                            }}
                          />
                          &nbsp; &nbsp;
                          <label htmlFor="isPublic">Public</label>
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          <Field
                            id="isOutdoor"
                            type="checkbox"
                            name="isOutdoor"
                            initialValues={data?.isOutdoor}
                            onChange={(e) => {
                              setData({
                                ...data,
                                isOutdoor: e.target.checked,
                              });
                              setFieldValue("isOutdoor", e.target.checked);
                            }}
                          />
                          &nbsp; &nbsp;
                          <label htmlFor="isOutdoor">Outdoors</label>
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            No. of Seats
                          </Label>
                          <Field
                            type="number"
                            className="form-control"
                            id="totalSeat"
                            name="totalSeat"
                            onChange={(e) => {
                              setData({ ...data, totalSeat: e.target.value });
                              setFieldValue("totalSeat", e.target.value);
                            }}
                          />
                          {errors.totalSeat && touched.totalSeat ? (
                            <div className="text-danger input-error">
                              {errors.totalSeat}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">Price</Label>
                          <Field
                            type="number"
                            className="form-control"
                            id="price"
                            name="price"
                            onChange={(e) => {
                              setData({ ...data, price: e.target.value });
                              setFieldValue("price", e.target.value);
                            }}
                          />
                          {errors.price && touched.price ? (
                            <div className="text-danger input-error">
                              {errors.price}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Min Cancellation Window (hours){" "}
                            <small>(optional)</small>
                          </Label>
                          <Field
                            type="number"
                            className="form-control"
                            value={data.minCancelWindow}
                            id="minCancelWindow"
                            name="totalminCancelWindowSeat"
                            onChange={(e) => {
                              setData({
                                ...data,
                                minCancelWindow: e.target.value,
                              });
                              setFieldValue("minCancelWindow", e.target.value);
                            }}
                          />
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="formrow-firstname-Input">
                            Location
                          </Label>
                          <SelectAsyncPaginate
                            name="location"
                            // regionName={region.value}
                            loadOptions={LoadLocations}
                            placeHolder="Select Location"
                            isMulti={false}
                            value={data.location}
                            onChange={(location) => {
                              console.log(location);
                              setData({ ...data, location });
                              console.log(location);
                              console.log(data);
                            }}
                          />
                          {errors.location && touched.location ? (
                            <div className="text-danger input-error">
                              {errors.location}
                            </div>
                          ) : null}
                        </div>
                        <div>
                          <Label htmlFor="formrow-firstname-Input">
                            Image <small>(required)</small>
                          </Label>
                          <div className="text-center">
                            <div className="text-center">
                              <div className="mb-3 dragdrop-container">
                                <input
                                  ref={uploadRef}
                                  id="upload"
                                  hidden
                                  accept="image/*"
                                  type="file"
                                  onChange={(e) =>
                                    handleImageChange(e.target.files)
                                  }
                                />
                                {image.src ? (
                                  <div className="commonImgs">
                                    <img
                                      className="every-img"
                                      src={image.src ? image.src : cloud}
                                      alt=""
                                      onClick={() => {
                                        uploadRef.current.click();
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="drag-n-drop-container">
                                    <div>
                                      <Dropzone
                                        accept="image/*"
                                        multiple={false}
                                        onDrop={(acceptedFiles) => {
                                          handleImageChange(acceptedFiles);
                                        }}
                                      >
                                        {({
                                          getRootProps,
                                          getInputProps,
                                          isDragActive,
                                        }) => (
                                          <section>
                                            <div
                                              className="drop-area"
                                              {...getRootProps()}
                                            >
                                              <img
                                                width={60}
                                                src={cloud}
                                                alt=""
                                              />
                                              <input
                                                {...getInputProps()}
                                                accept="image/*"
                                                multiple={false}
                                              />

                                              {isDragActive ? (
                                                <div>
                                                  Drop your image file here
                                                </div>
                                              ) : (
                                                <p>
                                                  Drag n drop image file here,
                                                  or click to select <br />
                                                  <small className="text-center">
                                                    <strong>
                                                      Supported files:
                                                    </strong>{" "}
                                                    jpeg, jpg, png. | Will be
                                                    resized to: 1920x1080 px.
                                                  </small>
                                                </p>
                                              )}
                                            </div>
                                          </section>
                                        )}
                                      </Dropzone>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <p className="fw-bold">
                              Note:
                              <span className="text-danger mx-2 text-align-left">
                                Supported image formats are:&nbsp;jpg, png and
                                jpeg only
                              </span>
                            </p>
                            {errors.file && touched.file ? (
                              <div className="text-danger input-error">
                                {errors.file}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {singleClass.classImage ? (
                          <div className="mb-3">
                            <Label htmlFor="formrow-firstname-Input">
                              Uploaded
                            </Label>
                            <div>
                              <img
                                className="previousImage"
                                src={`${process.env.REACT_APP_ASSET_URL}${singleClass.classImage}`}
                                alt=""
                                style={{ maxHeight: "120px" }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="pt-5">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Formik>
        </Container>
        {/* container-fluid */}
      </div>
    </React.Fragment>
  );
};

export default ClassesEdit;
