import React from "react";
import { Redirect } from "react-router-dom";

import Auth from "../Auth/Auth";
import Dashboard from "../Backend/Admin/Dashboard";
import Classes from "../Backend/Admin/classes/Classes";
import ClassesAdd from "../Backend/Admin/classes/ClassesAdd";
import ClassesEdit from "../Backend/Admin/classes/ClassesEdit";
import ClassesClone from "../Backend/Admin/classes/ClassesClone";
import Sessions from "../Backend/Admin/sessions/Sessions";
import SessionDetails from "../Backend/Admin/sessions/SessionDetails";
import SessionAdd from "../Backend/Admin/sessions/SessionAdd";
import SessionEdit from "../Backend/Admin/sessions/SessionEdit";

import Users from "../Backend/Admin/users/Users";
import UsersAdd from "../Backend/Admin/users/UsersAdd";
import UsersEdit from "../Backend/Admin/users/UsersEdit";
import UserView from "../Backend/Admin/users/UserView";

import SplashScreen from "../Backend/Admin/splashScreen/SplashScreen";

import CategoriesAdd from "../Backend/Admin/categories/CategoriesAdd";
import CategoriesEdit from "../Backend/Admin/categories/CategoriesEdit";
import Categories from "../Backend/Admin/categories/Categories";

import Providers from "../Backend/Admin/providers/Providers";
import ProvidersAdd from "../Backend/Admin/providers/ProvidersAdd";
import ProvidersEdit from "../Backend/Admin/providers/ProvidersEdit";
import NewProviders from "../Backend/Admin/providers/NewProviders";
import ProviderView from "../Backend/Admin/providers/ProviderView";

import Locations from "../Backend/Admin/locations/Locations";
import LocationsAdd from "../Backend/Admin/locations/LocationsAdd";
import LocationsEdit from "../Backend/Admin/locations/LocationsEdit";

import Holidays from "../Backend/Admin/holidays/Holidays";

import PrivacyPolicies from "../Backend/Admin/staticPages/PrivacyPolicies";
import TermsConditionsUser from "../Backend/Admin/staticPages/TermsConditionsUser";
import TermsConditionsInstructor from "../Backend/Admin/staticPages/TermsConditionsInstructor";
// import AboutUs from "../Backend/Admin/staticPages/AboutUs"
import Faq from "../Backend/Admin/staticPages/Faq";
import ContactUs from "../Backend/Admin/staticPages/ContactUs";

import Logout from "../Auth/Logout";

import Incidents from "../Backend/Admin/incidents/Incidents";
import ReportedIncidents from "../Backend/Admin/incidents/ReportedIncidents";
import IncidentsEdit from "../Backend/Admin/incidents/IncidentsEdit";
import IncidentsAdd from "../Backend/Admin/incidents/IncidentsAdd";

import Pages404 from "../Backend/Admin/Pages404";

import InstructorTransaction from "../Backend/Admin/transactions/InstructorTransaction";
import UserTransaction from "../Backend/Admin/transactions/UserTransaction";

import TermsAndCondition from "../frontend/TermsCondition";
import PrivacyPolicy from "../frontend/PrivacyPolicy";
import TermsConditionsWeb from "../Backend/Admin/staticPages/TermsConditionsWeb";
import PrivacyPoliciesWeb from "../Backend/Admin/staticPages/PrivacyPoliciesWeb";

const normalRoutes = [
  { path: "/login", component: Auth },
  { path: "/logout", component: Logout },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },

  { path: "/termsAndCondition", component: TermsAndCondition },
  { path: "/privacyPolicy", component: PrivacyPolicy },
];

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/all-classes", component: Classes },
  { path: "/all-classes/add", component: ClassesAdd },
  { path: "/classes/:id/edit", component: ClassesEdit },
  { path: "/classes/:id/clone", component: ClassesClone },
  { path: "/classes/:id/sessions", component: Sessions },
  { path: "/classes/:id/sessions/:sessionid", component: SessionDetails },
  { path: "/classes/:id/sessionAdd", component: SessionAdd },
  { path: "/classes/:id/sessionEdit/:sessionid", component: SessionEdit },

  { path: "/all-users", component: Users },
  { path: "/all-users/add", component: UsersAdd },
  { path: "/users/:id/edit", component: UsersEdit },
  { path: "/users/:id/view", component: UserView },

  { path: "/all-categories", component: Categories },
  { path: "/all-categories/add", component: CategoriesAdd },
  { path: "/categories/:id/edit", component: CategoriesEdit },

  { path: "/all-incidents", component: Incidents },
  { path: "/all-reported-incidents", component: ReportedIncidents },
  { path: "/incidents/:id/edit", component: IncidentsEdit },
  { path: "/all-incidents/add", component: IncidentsAdd },

  { path: "/all-providers", component: Providers },
  { path: "/all-providers/add", component: ProvidersAdd },
  { path: "/providers/:id/edit", component: ProvidersEdit },
  { path: "/providers-request", component: NewProviders },
  { path: "/providers/:id/view", component: ProviderView },

  { path: "/all-locations", component: Locations },
  { path: "/all-locations/add", component: LocationsAdd },
  { path: "/locations/:id/edit", component: LocationsEdit },

  { path: "/holidays", component: Holidays },

  { path: "/privacy_policies", component: PrivacyPolicies },
  { path: "/privacy_policies/web", component: PrivacyPoliciesWeb },
  // {path:'/about_us',component:AboutUs},
  { path: "/faq", component: Faq },
  { path: "/terms&conditions/user", component: TermsConditionsUser },
  { path: "/terms&conditions/web", component: TermsConditionsWeb },
  {
    path: "/terms&conditions/instructor",
    component: TermsConditionsInstructor,
  },
  { path: "/contact_us", component: ContactUs },

  { path: "/all-splash-screen", component: SplashScreen },

  { path: "/transaction/instructor", component: InstructorTransaction },
  { path: "/transaction/user", component: UserTransaction },

  { path: "*", component: Pages404 },

  // { path: "/", exact: true, component: () => <Redirect to="/login" /> }
];
export { authProtectedRoutes, normalRoutes };
