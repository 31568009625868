import { GET_HOLIDAYS_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  holidays: {},
  error: {},
  singleHoliday: {},
};

const Holidays = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_HOLIDAYS_SUCCESS:
      console.log("holidays ====> ", action.payload.data);
      return {
        ...state,
        holidays: action.payload.data.holidays,
      };

    default:
      return state;
  }
};

export default Holidays;
