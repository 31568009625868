import { del, get, post, put, patch } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};
// dashboard Api

export const dashboarsApi = (data) => get(url.GET_DASHBOARD_DATA);

// Classes API's
export const getClassesApi = (data) => post(`${url.GET_CLASSES}`, data);
export const getClassSessionsApi = (id) =>
  get(`${url.GET_CLASS_SESSIONS}?id=${id}`);
export const getSessionDetailsApi = (id) =>
  get(`${url.GET_SESSION_DETAILS}?id=${id}`);
export const addSessionApi = (data) => post(`${url.ADD_SESSION}`, data);
export const editSessionApi = (data) => post(`${url.EDIT_SESSION}`, data);
export const cancelSessionApi = (id, data) =>
  put(`${url.CANCEL_SESSION}/${id}`, data);

export const addNewClassesApi = (data) => post(url.ADD_NEW_CLASSES, data);
export const deleteClassesApi = (id) => del(`${url.DELETE_CLASSES}/${id}`);
export const updateClassesApiImage = (id, data) =>
  post(`${url.UPDATE_CLASSES_IMAGE}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const editClassesApi = (id) => get(`${url.EDIT_CLASSES}/${id}`);
export const updateClassesApi = (id, data) =>
  put(`${url.UPDATE_CLASSES}/${id}`, data);
export const postLogin = (data) => post(url.POST_LOGIN, data);

//  Users API's
export const getUsersApi = (page, searchKeyword) =>
  get(`${url.GET_USERS}?page=${page}&search=${searchKeyword}&limit=10`);
export const addNewUsersApi = (data) => post(url.ADD_NEW_USERS, data);
export const deleteUsersApi = (id) => del(`${url.DELETE_USERS}/${id}`);
export const editUsersApi = (id) => get(`${url.EDIT_USERS}/${id}`);
export const updateUsersApi = (id, data) =>
  put(`${url.UPDATE_USERS}/${id}`, data);

//  Categories API's
export const getCategoriesApi = (page) =>
  get(`${url.GET_CATEGORIES}?page=${page}&limit=10`);
export const addNewCategoriesApi = (data) =>
  post(url.ADD_NEW_CATEGORIES, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const deleteCategoriesApi = (id) =>
  del(`${url.DELETE_CATEGORIES}/${id}`);
export const editCategoriesApi = (id) => get(`${url.EDIT_CATEGORIES}/${id}`);
export const updateCategoriesApi = (id, data) =>
  put(`${url.UPDATE_CATEGORIES}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

//  Incidents API's
export const getIncidentsApi = (page, searchKeyword = "") =>
  get(`${url.GET_INCIDENTS}?page=${page}&search=${searchKeyword}&limit=10`);
export const getReportedIncidentsApi = (page) =>
  get(`${url.GET_REPORTEDINCIDENTS}?page=${page}&limit=10`);
export const addNewIncidentsApi = (data) =>
  post(url.ADD_NEW_INCIDENTS, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const deleteIncidentsApi = (id) => del(`${url.DELETE_INCIDENTS}/${id}`);
export const editIncidentsApi = (id) => get(`${url.EDIT_INCIDENTS}/${id}`);
export const updateIncidentsApi = (id, data) =>
  put(`${url.UPDATE_INCIDENTS}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

//  Cms API's
export const getCmsApi = (page) => get(`${url.GET_CMS}?page=${page}&limit=10`);
export const addNewCmsApi = (data) => post(url.ADD_NEW_CMS, data);
export const deleteCmsApi = (id) => del(`${url.DELETE_CMS}/${id}`);
export const editCmsApi = (id) => get(`${url.EDIT_CMS}/${id}`);
export const updateCmsApi = (id, data) =>
  put(`${url.UPDATE_CMS}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

//  Transactions API's
export const getTransactionsApi = (page) =>
  get(`${url.GET_TRANSACTIONS}?page=${page}&limit=10`);
export const getUserTransactionsApi = (page) =>
  get(`${url.GET_USER_TRANSACTIONS}?page=${page}&limit=10`);
export const addNewTransactionsApi = (data) =>
  post(url.ADD_NEW_TRANSACTIONS, data);
export const deleteTransactionsApi = (id) =>
  del(`${url.DELETE_TRANSACTIONS}/${id}`);
export const editTransactionsApi = (id) =>
  get(`${url.EDIT_TRANSACTIONS}/${id}`);
export const updateTransactionsApi = (id, data) =>
  put(`${url.UPDATE_TRANSACTIONS}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

//  SplashScreen API's
export const getSplashScreenApi = (page) =>
  get(`${url.GET_SPLASH_SCREEN}?page=${page}&limit=10`);
export const addNewSplashScreenApi = (data) =>
  post(url.ADD_NEW_SPLASH_SCREEN, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const deleteSplashScreenApi = (id) =>
  del(`${url.DELETE_SPLASH_SCREEN}/${id}`);
export const editSplashScreenApi = (id) =>
  get(`${url.EDIT_SPLASH_SCREEN}/${id}`);
export const updateSplashScreenApi = (id, data) =>
  put(`${url.UPDATE_SPLASH_SCREEN}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

//  Instructors API's
export const getProvidersApi = (page, searchKeyword) =>
  get(`${url.GET_PROVIDERS}?page=${page}&search=${searchKeyword}&limit=10`);
export const getNotVerifiedProvidersApi = (page) =>
  get(`${url.GET_PROVIDERS}?isVerified=false&page=${page}&limit=10`);
export const settleProviderPaymentApi = (data) =>
  post(url.SETTLE_PROVIDERS_PAYMENT, data);
export const addNewProvidersApi = (data) =>
  post(url.ADD_NEW_PROVIDERS, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
export const deleteProvidersApi = (id) => del(`${url.DELETE_PROVIDERS}/${id}`);
export const editProvidersApi = (id) => get(`${url.EDIT_PROVIDERS}/${id}`);
export const getProvidersDetailApi = (id) => get(`${url.EDIT_PROVIDERS}/${id}`);
export const updateProvidersApi = (id, data) =>
  put(`${url.UPDATE_PROVIDERS}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

//  Locations API's
export const getLocationsApi = (page, searchKeyword) =>
  get(`${url.GET_LOCATIONS}?page=${page}&search=${searchKeyword}&limit=10`);
export const addNewLocationsApi = (data) => post(url.ADD_NEW_LOCATIONS, data);
export const deleteLocationsApi = (id) => del(`${url.DELETE_LOCATIONS}/${id}`);
export const editLocationsApi = (id) => get(`${url.EDIT_LOCATIONS}/${id}`);
export const updateLocationsApi = (id, data) =>
  put(`${url.UPDATE_LOCATIONS}/${id}`, data);

// Locations API's
export const getHolidaysApi = (startDate, endDate) =>
  get(`${url.GET_HOLIDAYS}?start=${startDate}&end=${endDate}`);
export const saveHolidayApi = (data) => post(url.SAVE_HOLIDAY, data);
export const deleteHolidayApi = (id) => del(`${url.DELETE_HOLIDAY}/${id}`);

export { getLoggedInUser, isUserAuthenticated };
