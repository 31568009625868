import {
    GET_PROVIDERS_SUCCESS,
    GET_PROVIDERS_FAIL,
    ADD_PROVIDERS_SUCCESS,
    GET_PROVIDERS_DETAIL_SUCCESS,
    GET_PROVIDERS_DETAIL_FAIL,
    ADD_PROVIDERS_FAIL,
    DELETE_PROVIDERS_SUCCESS,
    DELETE_PROVIDERS_FAIL,
    EDIT_PROVIDERS_FAIL,
    EDIT_PROVIDERS_SUCCESS,
    UPDATE_PROVIDERS_SUCCESS,
    GET_NOT_VERIFIED_PROVIDERS_SUCCESS,
    GET_NOT_VERIFIED_PROVIDERS_FAIL,
    SETTLE_PROVIDER_PAYMENT_SUCCESS,
    SETTLE_PROVIDER_PAYMENT_FAIL
} from "./actionTypes";

const INIT_STATE = {
    providers: {},
    error: {},
    singleProvider: {}
};

const Providers = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_PROVIDERS_SUCCESS:
            console.log('providers ====> ', action.payload.data)
            return {
                ...state,
                providers: action.payload.data
            };
        case GET_PROVIDERS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case GET_PROVIDERS_DETAIL_SUCCESS:
            console.log('provider Detail ====> ', action.payload.data)
            return {
                ...state,
                singleProvider: action.payload.data
            };
        case GET_PROVIDERS_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case GET_NOT_VERIFIED_PROVIDERS_SUCCESS:
            console.log('Not Verified providers ====> ', action.payload.data)
            return {
                ...state,
                providers: action.payload.data
            };
        case GET_NOT_VERIFIED_PROVIDERS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        case ADD_PROVIDERS_SUCCESS:
            console.log('Add providers ====> ', action.payload.data)
            return {
                ...state,
                providers: {
                    ...state.data,
                    data: [...state.data, action.payload]
                },
            };
        case ADD_PROVIDERS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case EDIT_PROVIDERS_SUCCESS:
            return {
                ...state,
                singleProvider: action.payload.data.data.users ? action.payload.data.data.users : action.payload.data.providers
            };
        case EDIT_PROVIDERS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case SETTLE_PROVIDER_PAYMENT_SUCCESS:
            return {
                ...state,
                singleProvider: action.payload.data.data.users ? action.payload.data.data.users : action.payload.data.providers
            };
        case SETTLE_PROVIDER_PAYMENT_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_PROVIDERS_SUCCESS:
            console.log('providers ====> ', action.payload.data.id)

            return {
                ...state,
                providers: {
                    ...state.providers,
                    providers: [...state.providers.providers.filter(item => item['_id'] != action.payload.data.id)]
                },
            };
        case UPDATE_PROVIDERS_SUCCESS:

            return {
                ...state,
                providers: {
                    ...state.providers,
                    data: state.providers.providers.map(item => item?.id == action.payload.id ? action.payload : item)
                },
            }

        case DELETE_PROVIDERS_FAIL:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }

}


export default Providers;