import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  // useEffect(() => {
  //   ref.current.recalculate();
  // });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Main Section ")}</li>
            <li>
              <Link to="/all-splash-screen">
                <i className="bx bxs-grid-alt"></i>
                <span>{props.t(" Splash Screen")}</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/all-categories" >
                <i className="bx bxs-grid-alt"></i>
                <span>{props.t(" Categories")}</span>
              </Link>
            </li> */}
            <li>
              <Link to="/all-classes">
                <i className="bx bx-calendar-event"></i>
                <span>{props.t("Classes")}</span>
              </Link>
            </li>
            <li>
              <Link to="/all-locations">
                <i className="bx bx-map-pin"></i>
                <span>{props.t(" Locations")}</span>
              </Link>
            </li>
            <li>
              <Link to="/holidays">
                <i className="bx bx-gift"></i>
                <span>{props.t(" Holidays")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-bookmark"></i>
                <span>{props.t("Incidents")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/all-incidents">
                    <span>{props.t(" Incident Categories")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/all-reported-incidents">
                    <span>{props.t("Reported Incidents")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Manage End Users")}</li>
            <li>
              <Link to="/all-users">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Users")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Manage Instructors")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/all-providers">
                    <span>{props.t("Instructors")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/providers-request">
                    <span>{props.t("New Instructors Request")}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="menu-title">{props.t("Manage Payments")}</li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-dollar"></i>
                <span>{props.t("Transactions")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/transaction/instructor">
                    <span>{props.t("Instructor Transaction")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/transaction/user">
                    <span>{props.t("User Transaction")}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="menu-title">{props.t("Static Pages")}</li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("CMS Pages")}</span>
              </Link>
              <ul className="sub-menu">
                {/* <li>
                  <Link to="/#" className="has-arrow ">
                    <span>{props.t("Website")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/terms&conditions/web" >
                        <span>{props.t("Terms & conditions")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy_policies/web" >
                        <span>{props.t("Privacy Policy")}</span>
                      </Link>
                    </li>
                  </ul>
                </li> */}

                <li>
                  <Link to="/#" className="has-arrow ">
                    <span>{props.t("Terms & Conditions")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/terms&conditions/user">
                        <span>{props.t("User")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/terms&conditions/instructor">
                        <span>{props.t("Instructor")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/privacy_policies">
                    <span>{props.t("Privacy Policies")}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/faq" >
                    <span>{props.t("FAQ's")}</span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="/about_us" >
                    <span>{props.t("About Us")}</span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/contact_us">
                    <span>{props.t("Contact Us")}</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
