import React, { useEffect, useState } from "react";
import { cilZoom, cilPencil, cilDelete } from "@coreui/icons";

import { Link, useParams } from "react-router-dom";

import NoImgFound from "../../../assets/No-image-found.jpg";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Label,
} from "reactstrap";
import { Field, Formik, Form } from "formik";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import { stopLoader } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { setTokenHeader } from "../../../helpers/api_helper";
import {
  editClasses,
  getClassSessions,
  cancelSession,
} from "../../../store/classes/actions";
import moment from "moment";
import { startLoader } from "../../../store/actions";

const Sessions = () => {
  moment.tz.setDefault("Etc/UTC");

  const { classSessions, singleClass } = useSelector((state) => state.Classes);
  const [cancelDialogIsVisible, setCancelDialogIsVisible] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [cancelNote, setCancelNote] = useState("");
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));
    setTokenHeader(data.access_token);
    dispatch(stopLoader());
    dispatch(getClassSessions(params.id));
    dispatch(editClasses(params.id));
  }, []);

  useEffect(() => {
    console.log("singleClass : ", singleClass);
  }, [singleClass]);
  useEffect(() => {
    console.log("classSessions : ", classSessions);
    classSessions?.data?.sort((a, b) => a.date > b.date);
  }, [classSessions]);

  //meta title
  document.title = "Class Detail";

  const submitCancelSession = async () => {
    console.log("cancelSession id:", selectedSessionId);
    console.log("cancelNote:", cancelNote);

    dispatch(startLoader());
    await dispatch(cancelSession(selectedSessionId, cancelNote));
    await dispatch(getClassSessions(params.id));
    classSessions?.data?.sort((a, b) => a.date > b.date);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Class"
            breadcrumbItem={classSessions?.users?.name}
          />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft"></div>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <div className="  mb-4"></div>
                      <h5 className="font-size-15 text-truncate text-center">
                        {singleClass?.title} Class
                      </h5>
                    </Col>

                    <Col sm={12} className="text-center">
                      {singleClass?.classImage != null ||
                      singleClass?.classImage != "" ? (
                        <img
                          src={singleClass?.classImage}
                          alt=""
                          className="img-thumbnail "
                          style={{ maxHeight: "200px" }}
                        />
                      ) : (
                        <img
                          src={NoImgFound}
                          alt=""
                          className="img-thumbnail "
                          style={{ maxHeight: "200px" }}
                        />
                      )}
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Location :</th>
                          <td>{singleClass?.location?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Start Time :</th>
                          <td>
                            {moment(singleClass?.startTime).format("hh:mm a")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Duration :</th>
                          <td>{singleClass?.duration} min</td>
                        </tr>
                        <tr>
                          <th scope="row">Session Price :</th>
                          <td>${singleClass?.price}</td>
                        </tr>
                        <tr>
                          <th scope="row">Total Seats :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.totalSeat}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">First Session Date :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.firstSessionDate
                              ? moment(singleClass.firstSessionDate).format(
                                  "ddd, MMM DD"
                                )
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">End by Date :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.endByDate
                              ? moment(singleClass.endByDate).format(
                                  "ddd, MMM DD"
                                )
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Limit Number of Sessions :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.limitNumberOfSessions || "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Min Cancellation Window :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.minCancelWindow || "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4 h4">Instructor Detail</CardTitle>

                  <p className="text-muted mb-4">
                    {singleClass?.instructor?.personalDetail}
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <td colSpan={2} className="text-center">
                            {singleClass?.instructor?.userProfile != null ||
                            singleClass?.instructor?.userProfile != "" ? (
                              <img
                                src={singleClass?.instructor?.userProfile}
                                alt=""
                                className="img-thumbnail "
                                style={{ maxHeight: "200px" }}
                              />
                            ) : (
                              <img
                                src={NoImgFound}
                                alt=""
                                className="img-thumbnail "
                                style={{ maxHeight: "200px" }}
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{singleClass?.instructor?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{singleClass?.instructor?.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{singleClass?.instructor?.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Session Rate :</th>
                          <td>${singleClass?.instructor?.sessionRate}</td>
                        </tr>
                        <tr>
                          <th scope="row">Gender :</th>
                          <td>
                            {singleClass?.instructor?.gender == 0
                              ? "Male"
                              : "Female"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4 h4">Sessions</CardTitle>
                  <div className="mb-2">
                    <Link to={"sessionAdd"} className="btn btn-primary w-md">
                      Add +
                    </Link>
                  </div>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date</th>
                          <th>Enrolled</th>
                          <th>Is Cancelled?</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {classSessions?.data?.map((session, i) => {
                          return (
                            <tr key={i + "-file"}>
                              <th scope="row">{i + 1}</th>
                              <td>
                                {moment(session?.date).format("ddd, MMM DD")}
                              </td>
                              <td>{session?.enrolled?.length}</td>
                              <td>{session?.isCancelled ? "Yes" : "No"}</td>
                              <td>
                                <Link
                                  to={`sessions/${session._id}`}
                                  className="actionIcons"
                                >
                                  <CIcon
                                    icon={cilZoom}
                                    className="text-success hand me-3"
                                    style={{ height: "20px !important" }}
                                    title="View Detail"
                                    onClick={() => {
                                      // toggleViewModal()
                                      // setEdit(row);
                                    }}
                                  />
                                </Link>
                                <Link
                                  to={`sessionEdit/${session._id}`}
                                  className="actionIcons"
                                >
                                  <CIcon
                                    icon={cilPencil}
                                    className="text-warning hand me-2"
                                    style={{ height: "20px !important" }}
                                    title="Edit Session"
                                    onClick={() => {
                                      // toggleViewModal()
                                      // setEdit(row);
                                    }}
                                  />
                                </Link>
                                <CButton
                                  onClick={() => {
                                    if (session?.isCancelled) {
                                      return;
                                    }
                                    setCancelDialogIsVisible(true);
                                    setSelectedSessionId(session._id);
                                  }}
                                  color="danger"
                                  variant="ghost"
                                  size="sm"
                                >
                                  <CIcon
                                    icon={cilDelete}
                                    className={
                                      session?.isCancelled
                                        ? "text-muted"
                                        : "text-danger"
                                    }
                                    style={{ height: "20px !important" }}
                                    title="Cancel Session"
                                  />
                                </CButton>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <CModal
            visible={cancelDialogIsVisible}
            onClose={() => setCancelDialogIsVisible(false)}
            aria-labelledby="LiveDemoExampleLabel"
          >
            <CModalHeader onClose={() => setCancelDialogIsVisible(false)}>
              <CModalTitle id="LiveDemoExampleLabel">
                Cancel Session?
              </CModalTitle>
            </CModalHeader>
            <Formik
              enableReinitialize
              onSubmit={(e) => {
                return false;
              }}
            >
              <Form>
                {" "}
                <CModalBody>
                  <p>Are you sure you want to cancel this session?</p>
                  <div className="mb-3">
                    <Label htmlFor="formrow-firstname-Input">
                      Reason for Cancellation :
                    </Label>
                    <Field
                      type="text"
                      className="form-control"
                      id="cancelNote"
                      name="cancelNote"
                      onChange={(e) => {
                        setCancelNote(e.target.value);
                      }}
                    />
                  </div>
                </CModalBody>
              </Form>
            </Formik>

            <CModalFooter>
              <CButton
                color="secondary"
                onClick={() => setCancelDialogIsVisible(false)}
              >
                Cancel
              </CButton>
              <CButton
                color="danger"
                onClick={() => {
                  setCancelDialogIsVisible(false);
                  submitCancelSession();
                }}
              >
                Yes, Please Cancel It!
              </CButton>
            </CModalFooter>
          </CModal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Sessions;
