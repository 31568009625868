import React, { useEffect } from "react";

import { Link, useParams } from "react-router-dom";

import NoImgFound from "../../../assets/No-image-found.jpg";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { stopLoader } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { setTokenHeader } from "../../../helpers/api_helper";
import { editClasses, getSessionDetails } from "../../../store/classes/actions";
import moment from "moment";

const SessionDetails = () => {
  moment.tz.setDefault("Etc/UTC");

  const { sessionDetails, singleClass } = useSelector((state) => state.Classes);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));
    setTokenHeader(data.access_token);
    dispatch(stopLoader());

    console.log("params : ", params);
    dispatch(getSessionDetails(params.sessionid));
    dispatch(editClasses(params.id));
  }, []);

  useEffect(() => {
    console.log("sessionDetails : ", sessionDetails);
  }, [sessionDetails]);
  useEffect(() => {
    console.log("singleClass : ", singleClass);
  }, [singleClass]);

  //meta title
  document.title = "Session Details";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={singleClass?.title}
            breadcrumbItem={moment(sessionDetails?.date).format("ddd, MMM DD")}
          />

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft"></div>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <div className="  mb-4"></div>
                      <h5 className="font-size-15 text-truncate text-center">
                        {singleClass?.title} Class
                      </h5>
                    </Col>

                    <Col sm={12} className="text-center">
                      {singleClass?.classImage != null ||
                      singleClass?.classImage != "" ? (
                        <img
                          src={singleClass?.classImage}
                          alt=""
                          className="img-thumbnail "
                          style={{ maxHeight: "200px" }}
                        />
                      ) : (
                        <img
                          src={NoImgFound}
                          alt=""
                          className="img-thumbnail "
                          style={{ maxHeight: "200px" }}
                        />
                      )}
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Location :</th>
                          <td>{singleClass?.location?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Start Time :</th>
                          <td>
                            {moment(singleClass?.startTime).format("hh:mm a")}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Duration :</th>
                          <td>{singleClass?.duration} min</td>
                        </tr>
                        <tr>
                          <th scope="row">Session Price :</th>
                          <td>${singleClass?.price}</td>
                        </tr>
                        <tr>
                          <th scope="row">Enrolled :</th>
                          <td className="mb-0 txt-danger">
                            {`${sessionDetails?.enrolled?.length} / ${singleClass?.totalSeat}`}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">First Session Date :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.firstSessionDate
                              ? moment(singleClass.firstSessionDate).format(
                                  "ddd, MMM DD"
                                )
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">End by Date :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.endByDate
                              ? moment(singleClass.endByDate).format(
                                  "ddd, MMM DD"
                                )
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Limit Number of Sessions :</th>
                          <td className="mb-0 txt-danger">
                            {singleClass?.limitNumberOfSessions || "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4 h4">Instructor Detail</CardTitle>

                  <p className="text-muted mb-4">
                    {singleClass?.instructor?.personalDetail}
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <td colSpan={2} className="text-center">
                            {singleClass?.instructor?.userProfile != null ||
                            singleClass?.instructor?.userProfile != "" ? (
                              <img
                                src={singleClass?.instructor?.userProfile}
                                alt=""
                                className="img-thumbnail "
                                style={{ maxHeight: "200px" }}
                              />
                            ) : (
                              <img
                                src={NoImgFound}
                                alt=""
                                className="img-thumbnail "
                                style={{ maxHeight: "200px" }}
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>{singleClass?.instructor?.name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>{singleClass?.instructor?.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{singleClass?.instructor?.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">Session Rate :</th>
                          <td>${singleClass?.instructor?.sessionRate}</td>
                        </tr>
                        <tr>
                          <th scope="row">Gender :</th>
                          <td>
                            {singleClass?.instructor?.gender == 0
                              ? "Male"
                              : "Female"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4 h4">Students</CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sessionDetails?.enrolled?.map((student, i) => {
                          return (
                            <tr key={i + "-file"}>
                              <th scope="row">{i + 1}</th>
                              <td>{`${student?.fname} ${student?.lname}`}</td>
                              <td>{student?.email}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SessionDetails;
