import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_HOLIDAYS, SAVE_HOLIDAY, DELETE_HOLIDAY } from "./actionTypes";
import {
  getHolidaysSuccess,
  getHolidaysFail,
  saveHolidaySuccess,
  saveHolidayFail,
  deleteHolidaySuccess,
  deleteHolidayFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getHolidaysApi,
  saveHolidayApi,
  deleteHolidayApi,
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { stopLoader } from "../loaderState/actions";

function* getHolidays({ payload: { startDate, endDate } }) {
  try {
    console.log("start/end date", startDate, endDate);
    let response = yield call(getHolidaysApi, startDate, endDate);

    yield put(getHolidaysSuccess(response));
  } catch (error) {
    yield put(getHolidaysFail(error));
  }
  yield put(stopLoader());
}

function* saveHoliday({ payload: { data } }) {
  try {
    let response = yield call(saveHolidayApi, data);

    if (response.data.status == 201) {
      yield put(deleteHolidaySuccess(response));
      toast.success("Holiday Saved!!");
    } else {
      console.log("error message", response.message);
      toast.error(response.message);
    }
  } catch (error) {
    yield put(deleteHolidayFail(error));
    toast.error(error.message);
  }
  yield put(stopLoader());
}

function* deleteHoliday({ payload: { id } }) {
  try {
    let response = yield call(deleteHolidayApi, id);

    console.log("response", response);

    if (response.status == 201) {
      yield put(saveHolidaySuccess(response));
      toast.success("Holiday Deleted!!");
    } else {
      console.log("error message", response.message);
      toast.error(response.message);
    }
  } catch (error) {
    yield put(saveHolidayFail(error));
    toast.error(error.message);
  }
  yield put(stopLoader());
}

function* holidaysSaga() {
  yield takeEvery(GET_HOLIDAYS, getHolidays);
  yield takeEvery(SAVE_HOLIDAY, saveHoliday);
  yield takeEvery(DELETE_HOLIDAY, deleteHoliday);
}

export default holidaysSaga;
