import React, { useEffect, useState } from "react";
import { cilPencil, cilTrash } from "@coreui/icons";
import Image from "react-bootstrap/Image";

import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import DataTable from "react-data-table-component";
import { Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CIcon from "@coreui/icons-react";
import { useHistory, Link } from "react-router-dom";
import { toastConfirm } from "../../../common/toast";
import { useDispatch, useSelector } from "react-redux";
import { setTokenHeader } from "../../../helpers/api_helper";
import NoImgFound from "../../../assets/No-image-found.jpg";
import { startLoader } from "../../../store/loaderState/actions";
import {
  deleteCategories,
  getCategories,
} from "../../../store/categories/actions";

const Categories = () => {
  document.title = "Admin | All Categories";

  const { loadingState } = useSelector((state) => state.loadingState);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const dispatch = useDispatch();
  const history = useHistory();
  const { categories } = useSelector((state) => state.Categories);
  const deleteCategoriesWithId = async (id) => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire();
    if (response.isConfirmed) {
      dispatch(deleteCategories(id, history));
    }
  };

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));
    setTokenHeader(data.access_token);
    dispatch(startLoader());
    dispatch(getCategories());
    console.log(categories);
  }, []);

  useEffect(() => {
    dispatch(startLoader());
    dispatch(getCategories(page));
  }, [page]);

  useEffect(() => {
    console.log(categories);
  }, [categories]);

  const columns = [
    {
      name: "Sr. no",
      selector: (row, index) => {
        if (categories?.pagination?.page - 1 != 0) {
          return (categories?.pagination?.page - 1) * 10 + (index + 1);
        }
        return index + 1;
      },
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) =>
        row.image != null || row.image !== "" ? (
          <Image
            fluid={true}
            src={`${process.env.REACT_APP_ASSET_URL}${row.image}`}
            style={{ maxHeight: "20px" }}
          />
        ) : (
          <Image fluid={true} src={NoImgFound} />
        ),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <Link to={`categories/${row._id}/edit`}>
            <CIcon
              icon={cilPencil}
              className="text-warning hand me-2"
              onClick={() => {
                // toggleViewModal()
                // setEdit(row);
              }}
            />
          </Link>
          <CIcon
            icon={cilTrash}
            className="text-danger hand"
            onClick={() => {
              deleteCategoriesWithId(row._id);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content cast ">
        <div className="container-fluid">
          <Breadcrumbs
            title="All Categories"
            breadcrumbItem="Manage Categories"
          />

          <Row>
            <Col xl={12}>
              <div className="mb-2">
                <Link
                  to={"/all-categories/add"}
                  className="btn btn-primary w-md"
                >
                  Add +
                </Link>
              </div>
              <CCardGroup>
                <CCard>
                  <CCardBody>
                    <DataTable
                      striped
                      columns={columns}
                      data={categories?.data}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationTotalRows={categories?.pagination?.total}
                      paginationPerPage={countPerPage}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      onChangePage={(page, totalRows) => {
                        console.log("page : ", page);
                        console.log("totalRows : ", totalRows);
                        setPage(page);
                      }}
                    />
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Categories;
