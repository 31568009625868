/** Get Dashboard Chart data */
export const GET_CLASSES = "GET_CLASSES";
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS";
export const GET_CLASSES_FAIL = "GET_CLASSES_FAIL";

export const GET_CLASS_SESSIONS = "GET_CLASS_SESSIONS";
export const GET_CLASS_SESSIONS_SUCCESS = "GET_CLASS_SESSIONS_SUCCESS";
export const GET_CLASS_SESSIONS_FAIL = "GET_CLASS_SESSIONS_FAIL";

export const GET_SESSION_DETAILS = "GET_SESSION_DETAILS";
export const GET_SESSION_DETAILS_SUCCESS = "GET_SESSION_DETAILS_SUCCESS";
export const GET_SESSION_DETAILS_FAIL = "GET_SESSION_DETAILS_FAIL";

export const ADD_SESSION = "ADD_SESSION";
export const ADD_SESSION_SUCCESS = "ADD_SESSION_SUCCESS";
export const ADD_SESSION_FAIL = "ADD_SESSION_FAIL";

export const EDIT_SESSION = "EDIT_SESSION";
export const EDIT_SESSION_SUCCESS = "EDIT_SESSION_SUCCESS";
export const EDIT_SESSION_FAIL = "EDIT_SESSION_FAIL";

export const CANCEL_SESSION = "CANCEL_SESSION";
export const CANCEL_SESSION_SUCCESS = "CANCEL_SESSION_SUCCESS";
export const CANCEL_SESSION_FAIL = "CANCEL_SESSION_FAIL";

export const GET_CLASS_USERS = "GET_CLASS_USERS";
export const GET_CLASS_USERS_SUCCESS = "GET_CLASS_USERS_SUCCESS";
export const GET_CLASS_USERS_FAIL = "GET_CLASS_USERS_FAIL";

export const ADD_CLASSES = "ADD_CLASSES";
export const ADD_CLASSES_SUCCESS = "ADD_CLASSES_SUCCESS";
export const ADD_CLASSES_FAIL = "ADD_CLASSES_FAIL";

export const DELETE_CLASSES = "DELETE_CLASSES";
export const DELETE_CLASSES_SUCCESS = "DELETE_CLASSES_SUCCESS";
export const DELETE_CLASSES_FAIL = "DELETE_CLASSES_FAIL";

export const EDIT_CLASSES = "EDIT_CLASSES";
export const EDIT_CLASSES_SUCCESS = "EDIT_CLASSES_SUCCESS";
export const EDIT_CLASSES_FAIL = "EDIT_CLASSES_FAIL";

export const UPDATE_CLASSES = "UPDATE_CLASSES";
export const UPDATE_CLASSES_SUCCESS = "UPDATE_CLASSES_SUCCESS";
export const UPDATE_CLASSES_FAIL = "UPDATE_CLASSES_FAIL";
