import {
  GET_CLASSES_SUCCESS,
  GET_CLASSES_FAIL,
  ADD_CLASSES_SUCCESS,
  ADD_CLASSES_FAIL,
  DELETE_CLASSES_SUCCESS,
  DELETE_CLASSES_FAIL,
  EDIT_CLASSES_FAIL,
  EDIT_CLASSES_SUCCESS,
  UPDATE_CLASSES_SUCCESS,
  GET_CLASS_SESSIONS_SUCCESS,
  GET_CLASS_SESSIONS_FAIL,
  GET_SESSION_DETAILS_SUCCESS,
  GET_SESSION_DETAILS_FAIL,
  ADD_SESSION_FAIL,
  EDIT_SESSION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  classes: {},
  error: {},
  singleClass: {},
  classUsers: {},
};

const Classes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload.data.data,
      };
    case GET_CLASSES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CLASS_SESSIONS_SUCCESS:
      return {
        ...state,
        classSessions: action.payload.data,
      };
    case GET_CLASS_SESSIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_SESSION_DETAILS_SUCCESS:
      return {
        ...state,
        sessionDetails: action.payload.data,
      };
    case GET_SESSION_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_SESSION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case EDIT_SESSION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_CLASSES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case EDIT_CLASSES_SUCCESS:
      return {
        ...state,
        singleClass: action.payload.data.data,
      };
    case EDIT_CLASSES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_CLASSES_SUCCESS:
      return {
        ...state,
        classes: {
          ...state.classes,
          data: [
            ...state.classes.data.filter(
              (item) => item["_id"] !== action.payload.data
            ),
          ],
          pagination: {
            ...state.classes.pagination,
            total: state.classes.pagination.total - 1,
          },
        },
      };

    case UPDATE_CLASSES_SUCCESS:
      return {
        ...state,
        classes: {
          ...state.classes,
          data: state.classes.data.map((item) =>
            item?.id == action.payload.id ? action.payload : item
          ),
        },
      };

    case DELETE_CLASSES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Classes;
