import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USERS,
  ADD_USERS_SUCCESS,
  ADD_USERS_FAIL,
  UPDATE_USERS,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FAIL,
  DELETE_USERS,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
  EDIT_USERS_SUCCESS,
  EDIT_USERS,
  EDIT_USERS_FAIL,
} from "./actionTypes";

export const getUsers = (page = 1, searchKeyword = "") => ({
  type: GET_USERS,
  payload: { page, searchKeyword },
});

export const getUsersSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  payload: { data },
});

export const getUsersFail = (error) => ({
  type: GET_USERS_FAIL,
  payload: { error },
});

export const addUsers = (data, history) => ({
  type: ADD_USERS,
  payload: { data, history },
});

export const addUsersSuccess = (data) => ({
  type: ADD_USERS_SUCCESS,
  payload: { data },
});

export const addUsersFail = (error) => ({
  type: ADD_USERS_FAIL,
  payload: { error },
});

export const deleteUsers = (id) => ({
  type: DELETE_USERS,
  payload: { id },
});

export const deleteUsersSuccess = (data) => ({
  type: DELETE_USERS_SUCCESS,
  payload: { data },
});

export const deleteUsersFail = (error) => ({
  type: DELETE_USERS_FAIL,
  payload: { error },
});
export const editUsers = (id) => ({
  type: EDIT_USERS,
  payload: { id },
});

export const editUsersSuccess = (data) => ({
  type: EDIT_USERS_SUCCESS,
  payload: { data },
});

export const editUsersFail = (error) => ({
  type: EDIT_USERS_FAIL,
  payload: { error },
});

export const updateUsersSuccess = (user) => ({
  type: UPDATE_USERS_SUCCESS,
  payload: user,
});

export const updateUsers = (id, user, history) => ({
  type: UPDATE_USERS,
  payload: { id, user, history },
});
export const updateUsersFail = (error) => ({
  type: UPDATE_USERS_FAIL,
  payload: error,
});
