import React, { useEffect, useState } from "react";
import { cilPencil, cilTrash } from "@coreui/icons";
import plus from "../../../assets/flix-images/plus.png";
import { CButton } from "@coreui/react";
import { Row, Col, Container, Card, CardImg, CardText } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CIcon from "@coreui/icons-react";
import { useHistory } from "react-router-dom";
import { toastConfirm } from "../../../common/toast";
import { useDispatch, useSelector } from "react-redux";
import { setTokenHeader } from "../../../helpers/api_helper";
import { startLoader } from "../../../store/loaderState/actions";
import {
  deleteSplashScreen,
  getSplashScreen,
} from "../../../store/splashScreen/actions";
import EditSplashScreen from "../modals/EditSplashScreenList";
import AddSplashScreen from "../modals/AddSplashScreenList";
import "./splashScreen.css";
import NoImgFound from "../../../assets/No-image-found.jpg";
const SplashScreen = () => {
  document.title = "Admin | All SplashScreen";

  const [addModal, setAddModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [editLabel, setEditLabel] = useState("");
  const toggleAddModal = () => setAddModal(!addModal);

  const [editModal, setEditModal] = useState(false);
  const toggleEditModal = () => setEditModal(!editModal);
  const dispatch = useDispatch();
  const history = useHistory();
  const { splashScreen } = useSelector((state) => state.SplashScreen);

  const deleteSplashScreenWithId = async (id) => {
    const response = await (
      await toastConfirm("Are you sure you want to delete this?")
    ).fire();
    if (response.isConfirmed) {
      dispatch(deleteSplashScreen(id, history));
    }
  };

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("authUser"));
    setTokenHeader(data.access_token);
    dispatch(startLoader());
    dispatch(getSplashScreen());
    console.log(splashScreen);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content cast ">
        <AddSplashScreen isOpen={addModal} toggle={toggleAddModal} />
        <EditSplashScreen
          isOpen={editModal}
          toggle={toggleEditModal}
          editId={editId}
          label={editLabel}
        />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="All Splash Screens"
            breadcrumbItem="Manage Splash Screens"
          />

          <div className="checkout-tabs">
            <Row>
              {splashScreen?.getScreen?.map((item, index) => {
                return (
                  <>
                    <Col mg={6} xl={2} key={index + 1} className="active">
                      <Card className="allSpashScreen-card">
                        <CardImg
                          top
                          className="img-fluid img"
                          src={item.image != "" ? item.image : NoImgFound}
                          alt={item.name}
                        />
                      </Card>

                      <div className="edit">
                        <CButton
                          variant="text"
                          className="newEditIcon p-0"
                          onClick={() => {
                            toggleEditModal();
                            setEditId(item["id"]);
                            setEditLabel(item["label"]);
                          }}
                        >
                          <CIcon icon={cilPencil} className="text-warning" />
                        </CButton>
                        <CButton
                          variant="text"
                          className="newDeleteIcon p-0"
                          onClick={() => deleteSplashScreenWithId(item["id"])}
                        >
                          <CIcon icon={cilTrash} className="text-danger" />
                        </CButton>
                      </div>
                    </Col>
                  </>
                );
              })}
              {splashScreen?.getScreen?.length < 3 ? (
                <Col mg={6} xl={2} className="add">
                  <Card
                    className="allSpashScreen-card d-flex align-items-center justify-content-center"
                    onClick={() => {
                      toggleAddModal();
                      // setEdit(row)
                    }}
                  >
                    <CardImg
                      top
                      className="img-fluid addImg"
                      src={plus}
                      alt="Skote"
                    />
                  </Card>
                </Col>
              ) : (
                ""
              )}
            </Row>
            {/* </CCardBody>
              </CCard> */}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SplashScreen;
