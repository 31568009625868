import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_USERS,
  ADD_USERS,
  DELETE_USERS,
  EDIT_USERS,
  UPDATE_USERS,
} from "./actionTypes";
import {
  getUsersFail,
  getUsersSuccess,
  addUsersFail,
  addUsersSuccess,
  deleteUsersSuccess,
  deleteUsersFail,
  editUsersSuccess,
  editUsersFail,
  updateUsersSuccess,
  updateUsersFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getUsersApi,
  addNewUsersApi,
  deleteUsersApi,
  editUsersApi,
  updateUsersApi,
} from "../../helpers/backend_helper";
import { toast } from "react-toastify";
import { stopLoader } from "../loaderState/actions";

function* getUsersData({ payload: { page, searchKeyword } }) {
  try {
    let response = yield call(getUsersApi, page, searchKeyword);
    yield put(getUsersSuccess(response));
  } catch (error) {
    yield put(getUsersFail(error));
  }
  yield put(stopLoader());
}

function* addUsersData({ payload: { data, history } }) {
  try {
    let response = yield call(addNewUsersApi, data);

    if (response.status == 201) {
      // yield put(getUsersSuccess(response));
      history.push("/all-users");
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    yield put(getUsersFail(error));
  }
  yield put(stopLoader());
}

function* editUsers({ payload: id }) {
  try {
    let response = yield call(editUsersApi, id.id);
    console.log("editUsersApi response", response);
    yield put(editUsersSuccess(response));
  } catch (error) {
    yield put(editUsersFail(error));
  }
  yield put(stopLoader());
}

function* onUpdateUsers({ payload: { id, user, history } }) {
  try {
    const response = yield call(updateUsersApi, id, user);
    console.log(history);
    if (response.status == 200) {
      history.push("/all-users");
      yield put(updateUsersSuccess(response.data));
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    yield put(updateUsersFail(error));
  }
  yield put(stopLoader());
}

function* deleteUsers({ payload: id }) {
  try {
    const response = yield call(deleteUsersApi, id.id);

    yield put(deleteUsersSuccess(id));
  } catch (error) {
    yield put(deleteUsersFail(error));
  }
  yield put(stopLoader());
}

function* usersSaga() {
  yield takeEvery(GET_USERS, getUsersData);
  yield takeEvery(ADD_USERS, addUsersData);
  yield takeEvery(EDIT_USERS, editUsers);
  yield takeEvery(UPDATE_USERS, onUpdateUsers);
  yield takeEvery(DELETE_USERS, deleteUsers);
}

export default usersSaga;
